import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class OtpserviceService {

  constructor(private http: HttpClient) { }

  sendOTP(mobile: number, email: string){
    return this.http.get(`${environment.server_url}api/ChatBot/SendOTP/?MobileNumber=${mobile}&emailid=${email}&Source=SLPTELE`, {})
  }

  validateOTP(mobile: number, otp: number) {
    return this.http.post(`${environment.server_url}api/ChatBot/VerifyOTP/`, {
      OTP : otp,
      MobileNumber: mobile,
      Source: "SLPTele"
    })
  }


}
