import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http'
import { environment } from 'src/environments/environment';
import { ToastrService } from 'ngx-toastr';



@Injectable({
  providedIn: 'root'
})
export class ContentService {

  constructor(
    private http: HttpClient,
    private toaster: ToastrService
    ) { }

  loadTherapists(
    ProviderName: string,
    Zip: string,
    State: string,
    LanguageId: string,
    Speciality: string,
    display = true
  ) {
    var initialData = {
      "TreatmentType": "",
      "ProviderName": ProviderName,
      "Zip": Zip,
      "State": State,
      "LanguageId" : LanguageId,
      "Speciality" : Speciality
    }
    
  }

  getAreaOfNeeds(){
    return this.http.get<AreaOfNeeds>(`${environment.server_url}api/Network/GetSpecialties?Companyid=1`)
  }

  showSucess(message: string, title: string) {
    this.toaster.success(message, title)
  }

  showError(message: string, title: string) {
    this.toaster.error(message, title)
  }

  showInfo(message: string, title: string){
    this.toaster.info(message, title)
  }
  
  showWarning(message: string, title: string){
      this.toaster.warning(message, title)
  }



}


export interface AreaOfNeeds {
  Version: boolean
  StateCode: number
  ErrorMessage: string
  Result: boolean
  
  ListResult: [ListResultOfAreaOfNeeds]
}


export interface ListResultOfAreaOfNeeds {
  SpecialtiesId: number
  SpecialtiesName: string
  CompanyId : number
  SequenceNo: number
  active: boolean
  checked: boolean
  intermedite: boolean
  SubSpecialties: SubSpecialtiesAreaOfNeeds[]
}


export interface SubSpecialtiesAreaOfNeeds {
  SubSpecialtiesId: number
  SpecialtiesId: number
  SubSpecialtiesName: string
  SequenceNo: number
  active: boolean
  checked: boolean
}

