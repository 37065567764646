<div class="spinn ">
    <ngx-spinner type="ball-scale-multiple"></ngx-spinner>
</div>

<div class="row therip_search_mn search_div container fnd_prov">
    <!-- 7 -->
        <div *ngIf="show == 'true'" class="seach_head">
            <p class="find_ap">FIND A PROVIDER</p><br>
                        <h1>Find the provider who's right for you.</h1>
        </div>
        <div  class="boxes drp_dwn therip_search">
            <mat-form-field class="mff" appearance="outline">
                <mat-label>Search Therapist By Name</mat-label>
                <input matInput type="text" [(ngModel)]="searchTherapist" id = "name" [matAutocomplete]="auto" (ngModelChange)="loadInitialTherapists(false)" placeholder="Search By Name">

                <mat-autocomplete #auto="matAutocomplete" >
                    <mat-option *ngFor="let thp of autoComplete " value="{{ thp.ProviderName }}" >
                        {{ thp.ProviderName }}
                    </mat-option>
                </mat-autocomplete>
            </mat-form-field>
                <mat-form-field class="mff"  appearance="outline">
                    <mat-label>Search by License State</mat-label>
                    <mat-select  name="filterState" id="" [(ngModel)]="filterState"    >
                        <mat-option *ngFor="let state of environment.statesInKeyShortCode" value="{{ state.value }}">{{ state.name }}</mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field class="mff" appearance="outline">
                    <mat-label>Search By Language</mat-label>
                    <mat-select  [(ngModel)]="languageFilter"  >
                        <mat-option *ngFor="let lang of environment.supportedLanguages" [value]="lang[1]">{{ lang[0] }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <!-- <mat-form-field style="width: 240px;" appearance="outline" >
                    <mat-label>Search By Specialities</mat-label>
                    <mat-select  [(ngModel)]="speacitlityFilter"  (ngModelChange)="loadInitialTherapists()">
                        <mat-option *ngFor="let spec of environment.areaOfSpecs" [value]="spec[1]">{{ spec[0] }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field appearance="outline">
                    <mat-label>Search by Zip Code</mat-label>
                    <input matInput type="text" [(ngModel)]="zipcodeFilter"  id="name" placeholder="Search By Zip Code">
                </mat-form-field> -->
                <!-- <button type="button" mat-raised-button (click)="clearSearch()">Clear</button> -->
        </div>

        <div class="centerbutton search_clear" >
            <button type="button" mat-raised-button (click)="clearSearch()" class="search_btn btn btn-light">Clear</button>
            <button type="button" mat-raised-button color="success" class="search_btn btn btn-success " (click)="loadInitialTherapists()" >Search</button>
        </div>
        
        <div *ngIf="therapistsData.length == 0" class="nores">
            <img src="assets/find-therap.png htw" alt="">
            <h1>
                Try Searching 🔎
            </h1>
        </div>


        <div class="row row2">
            <div  *ngFor="let item of therapistsData "   class="col-lg-3 form-check therap_prof" >
                <!-- (click)="takeToProfile(item.ProfileName)" -->
                    <button mat-stroked-button  class="cust_trp" >
                        <a routerLink="/provider-profile/{{ item.ProfileName }}" target="_blank" ><div class="provider_profile_pic" style="background-image: url('{{ item.PhotoFilePath }}');" alt="" >  </div></a>
                        <div class="eachtherapist">
                            <p class="pname">{{ item.ProviderName }} </p>
                                <div class="spancon">    
                                    <ul class="cnsul_sec">
                                    <li style="margin-right: 13px;"><p><img src="/assets/video-camera.png" class="v_icn" style="margin-right: 3px;"> Virtual Session</p></li>
                                    <li *ngIf="item.PrimaryLocation != null"><p> <img src="/assets/user.png" class="v_icn" style="margin-right: 3px;">In-Person Session</p></li>
                                    </ul>
                                </div>
                        </div>
                        <div  class="cust_discrib therip_slc_btn" >
                            <br>
                            <div *ngIf="item.specialities != null">
                                <p   class="desig_li" ><b>Focuses on : </b>{{ getAreaOfNeedsString(item.specialities) }}</p>
                            </div>
                            <br>
                            <div class="centerbutton">
                                <button mat-raised-button  class="btn btn-success therip_slct1" (click)="window.location.href = '/new-client'" >Request a Consultation</button>
                            </div>
                        </div>
                    </button>
                </div>
        </div>
</div>