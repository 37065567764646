<nav class="navbar  navbar-light  bg-light navbar-expand-sm" style="height: 80px;">
  <a class="navbar-brand image" href='#'><span><img src="assets/logo-final.png" width="160px"></span></a>
  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarContent">
    <span class="navbar-toggler-icon"></span>
  </button>

  <!-- <div class="collapse navbar-collapse" style="margin-left: 11%" id="navbarContent">
    <ul class="exo-menu">

      <li class="drop-down pad_tp">
         <a href="{{ environment.website }}our-programs/">Services </a>
         <ul class="drop-down-ul animated fadeIn">
            <li><a href="{{ environment.website }}our-programs/">Our Program</a></li>
            <li class="flyout-right">
               <a >FAQs <i class="fa fa-angle-right"></i></a>
               <ul class="animated fadeIn">
                  <li><a href="{{ environment.website }}what-is-speech-therapy/">What Is Speech Therapy?</a></li>
                  <li><a href="{{ environment.website }}speech-therapy-services/">How Telespeech Therapy Works?</a></li>
                  <li><a href="{{ environment.website }}what-is-a-speech-therapist/">What Is A Speech Therapist?</a></li>
                  <li><a href="{{ environment.website }}speech-and-language-developmental/">Developmental Milestones</a></li>
               </ul>
            </li>
            <li><a href="{{ environment.website }}health-insurance/">Health Insurance Accepted</a></li>
            <li><a href="{{ environment.website }}consultation-request/">Free Consultation Request</a></li>
            <li><a href="{{ environment.website }}consumernotices/">Consumer Notices</a></li>
            <li><a href="/new-client">Schedule a Consultation</a></li>

         </ul>
      </li>
      <li class="drop-down pad_tp">
         <a href="{{ environment.website }}speech-therapist">
            Speech Therapists </a>
         <ul class="drop-down-ul animated fadeIn">
            <li><a href="{{ environment.website }}speech-therapist/#why-work-for-us">Why Work with Us?</a></li>
            <li><a href="How it Works">How it Works</a></li>
            <li><a href="{{ environment.website }}speech-therapist/#faq">FAQ</a></li>
            <li><a href="{{ environment.website }}speech-therapist/#current-jobs">Current Assignments</a></li>
            <li><a href="{{ environment.website }}speech-therapist/#contact-a-recruiter">Contact a Recruiter</a></li>
            <li><a href="{{ environment.website }}employeenotices/">Employee Notices</a></li>
            <li><a href="/new-provider">Join Us</a></li>

         </ul>
      </li>
      <li class="pad_tp red_pad"><a href="{{ environment.website }}locations/"> Locations</a></li>
      <li class="pad_tp red_pad"><a href="https://my.slptele.com/"> LOG IN</a></li>
      <li class="drop-down pad_tp">
         <a> Menu </a>
         <ul class="drop-down-ul animated fadeIn">
            <li class="flyout-left">
               <a href="{{ environment.website }}partner/">I am a Collaborative Partner <i class="fa fa-angle-right"></i></a>


               <ul class="animated fadeIn">
                  <li><a href="{{ environment.website }}partner/#how-it-works">How it Works</a></li>
                  <li><a href="{{ environment.website }}partner/#services">Services</a></li>
                  <li><a href="{{ environment.website }}partner/#why-slptele">Why Us</a></li>
               </ul>


            </li>






            <li class="flyout-left"> <a
                  href="{{ environment.website }}national-referral-network-speech-therapy/">Partnership<i
                     class="fa fa-angle-right"></i></a>
               <ul class="animated fadeIn">
                  <li><a href="{{ environment.website }}national-referral-network-speech-therapy/">Referral Network</a></li>
                  <li><a href="{{ environment.website }}equity-partner-speech-therapy-clinics/">Equity Partner</a></li>
               </ul>
            </li>





            <li class="flyout-left"><a href="{{ environment.website }}about-us/">About Us<i
                     class="fa fa-angle-right"></i></a>
               <ul class="animated fadeIn">
                  <li><a href="{{ environment.website }}about-us/#mission-and-values">Mission &amp; Values</a></li>
                  <li><a href="{{ environment.website }}about-us/#our-difference">Our Difference</a></li>
                  <li><a href="{{ environment.website }}management-team/">Management Team</a></li>
               </ul>
            </li>






            <li class="flyout-left"><a href="{{ environment.website }}research-resources-speech-therapy/"
                  class="scnd_ttl">Links<i class="fa fa-angle-right"></i></a>
               <ul class="animated fadeIn">
                  <li><a href="{{ environment.website }}research-resources-speech-therapy/">Research / Resources</a></li>
                  <li><a href="{{ environment.website }}privacy-policy/">Privacy Policy</a></li>
                  <li><a
                        href="https://payments.google.com/payments/apis-secure/u/0/get_legal_document?ldt=buyertos&amp;ldr=US">Terms
                        and Conditions Credit Card Use</a></li>
                  <li><a href="{{ environment.website }}disclaimer/">Disclaimer</a></li>
                  <li><a href="{{ environment.website }}forms/">Forms</a></li>
                  <li><a href="tel:1-877-757-8353">Call Us! 1-877-757-8353</a></li>
               </ul>
            </li>



            <li class="flyout-left"><a href="{{ environment.website }}contact-us/" class="scnd_ttl">Contact us<i
                     class="fa fa-angle-right"></i></a>
               <ul class="animated fadeIn">
                  <li><a href="{{ environment.website }}speech-therapist/#contact-a-recruiter" aria-current="page">Contact a
                        Recruiter</a></li>
                  <li><a href="{{ environment.website }}request-therapy-appointment/">Request Therapy Appointment</a></li>
               </ul>
            </li>


         </ul>
      </li>
      <li class="pad_tp"><a href="/new-provider"> Join Us</a></li>
      
      <li class=" drop-down pad_tp no_drp">
         <a href="/new-client" class="get_started" style="font-weight: 900;" > Get Started</a>
         <ul class="drop-down-ul animated fadeIn">
            <li><a href="/new-client" >Schedule a Consultation</a></li>
         </ul>
      </li>
      
   </ul>
  </div> -->


