import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { environment } from 'src/environments/environment';
import { AreaOfNeeds, ContentService } from '../content.service'


@Component({
  selector: 'app-search-therapist',
  templateUrl: './search-therapist.component.html',
  styleUrls: ['./search-therapist.component.scss']
})
export class SearchTherapistComponent implements OnInit {
  @Input('show') show  = "true"
  @Input('state') filterState = "";
  @Input('lang')   languageFilter = "";
  searchTherapist = "";
  zipcodeFilter = "";

  speacitlityFilter = "";
  environment = environment;
  therapistsData: TherapistData[] = []
  autoComplete: TherapistData[] = []
  window = window
  json_data!: jsonAON[]

  constructor(
    private spinner: NgxSpinnerService,
    private http: HttpClient,
    private content: ContentService
  ) { }
  areaOfNeeds!: AreaOfNeeds

  getStateWithZipCode() {
    this.http.get<states[]>("assets/states.json").subscribe(
      data => {
        this.filterState = data.find(x => x.zip == this.zipcodeFilter)?.state || ""
      }
    )
  }

  
  convertAONIntoJson() {
    var d: jsonAON[] = []
    this.areaOfNeeds.ListResult.forEach(
      element => {
        var SpecialtiesName = element.SpecialtiesName
        element.SubSpecialties.forEach(
          subelement => {
            var temp: any = {}
            temp.SubSpecialtiesId = subelement.SubSpecialtiesId
            temp.SpecialtiesId = subelement.SpecialtiesId
            temp.SubSpecialtiesName = subelement.SubSpecialtiesName
            temp.SpecialtiesName = SpecialtiesName
            d.push(temp)
          }
        )
      }
    )
    return d
  }

  getAreaOfNeedsString(data: string) {
    var finalString = ""
    var data_split: string[] = data.split(",")  
    var main_headings: Set<string> = new Set()
    data_split.forEach(
      element => {
        var temp = this.json_data.find(x => x.SubSpecialtiesId == Number(element))?.SpecialtiesName
        if (temp != undefined ){
          if (!main_headings.has(temp)) {
            main_headings.add(temp)
            finalString += temp + ", "
          }
        }
      }
    )
    return finalString
  }

  
  loadInitialTherapists(display = true) {
    display == true ? this.spinner.show() : this.spinner.hide()
    var initialData = {
      "TreatmentType": "",
      "ProviderName":this.searchTherapist,
      "Zip":this.zipcodeFilter,
      "State":this.filterState,
      "LanguageId" : this.languageFilter,
      "Speciality" : this.speacitlityFilter
    }
    this.http.post(`${environment.server_url}api/Network/SearchProvider`, initialData).subscribe(
      ((data: any) => {
        // console.log(data)
        window.localStorage.clear()
        this.therapistsData.splice(0, this.therapistsData.length)
        this.autoComplete.splice(0, this.autoComplete.length)

        data['ListResult'].forEach((element: TherapistData) => {
          if (display){
          this.therapistsData.push(new TherapistData(element.userid, element.ProviderName, element.specialities, 
            element.PhotoFilePath != "" ? element.PhotoFilePath : environment.avatarURL , 
            element.WhyMe, element.state, element.areaofexpertise, element.ProfileName, element.OutOfPocketRateFrom, element.OutOfPocketRateTo, element.PrimaryLocation, element.language, element.LicenseName))
          }
          else {
            this.autoComplete.push(new TherapistData(element.userid, element.ProviderName, element.specialities, 
              element.PhotoFilePath != "" ? element.PhotoFilePath : environment.avatarURL , 
              element.WhyMe, element.state, element.areaofexpertise, element.ProfileName, element.OutOfPocketRateFrom, element.OutOfPocketRateTo, element.PrimaryLocation, element.language, element.LicenseName))
          }
        });
        window.localStorage.setItem("therapistData", JSON.stringify(this.therapistsData))
        this.spinner.hide()
      })
    )
  }


  
  clearSearch() {
    this.searchTherapist = "";
    this.filterState = "";
    this.languageFilter = "";
    this.speacitlityFilter = ""
    this.therapistsData.splice(0, this.therapistsData.length)
  }

  
  ngOnInit(): void {
    if (this.filterState != "") {
      this.loadInitialTherapists()
    }
    this.getStateWithZipCode()
    this.content.getAreaOfNeeds().subscribe(
      (data) => {
        this.areaOfNeeds = data;
        this.json_data = this.convertAONIntoJson()
        // console.log(this.json_data)
      }
    )
    
  }
  takeToProfile(profileName: string) {
    this.window.open(`/provider-profile/${profileName}`, '_blank')
  }

}


export class TherapistData{
  userid!: number;
  ProviderName!: string;
  specialities!: string;
  PhotoFilePath!: any;
  WhyMe!: string;
  state!: string;
  areaofexpertise!: string;
  hover!: boolean;
  selected!: boolean;
  ProfileName!: string;
  OutOfPocketRateFrom!: string;
  OutOfPocketRateTo!: string;
  PrimaryLocation!: string;
  language!: string;
  LicenseName!: string;

  constructor(
    userid: number,
    ProviderName: string,
    specialities: string,
    PhotoFilePath: any,
    WhyMe: string,
    state: string,
    areaofexpertise: string,
    ProfileName: string,
    OutOfPocketRateFrom: string,
    OutOfPocketRateTo: string,
    PrimaryLocation: string,
    language: string,
    LicenseName: string,
    hover: boolean = false,
    selected: boolean = false
    ) {
      this.userid = userid
      this.ProviderName = ProviderName;
      this.specialities = specialities;
      this.PhotoFilePath = PhotoFilePath;
      this.WhyMe = WhyMe;
      this.state = state;
      this.areaofexpertise = areaofexpertise;
      this.hover = hover;
      this.selected = selected;
      this.ProfileName = ProfileName,
      this.OutOfPocketRateFrom = OutOfPocketRateFrom
      this.OutOfPocketRateTo = OutOfPocketRateTo
      this.PrimaryLocation = PrimaryLocation
      this.language = language
      this.LicenseName = LicenseName
    } 

}


export interface states {
  state: string;
  zip: string
}

export interface jsonAON {
  SubSpecialtiesId: number
  SpecialtiesId: number
  SubSpecialtiesName: string
  SpecialtiesName: string
}