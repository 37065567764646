<ngx-spinner type="ball-scale-multiple"></ngx-spinner>

<div class="container">

    <div class="leftcontainer">
        <div class="form" *ngIf="page == 1">

            <form [formGroup]="firstFormGroup" class="form_first">
                <div class="col-lg-6 first_form">
                    <h1 class="head_h">
                        Let's start with basics
                    </h1>
                    <div class="row">
                        <mat-form-field appearance="outline">
                            <mat-label> First Name </mat-label>
                            <input matInput type="text" placeholder="Your First Name" formControlName="fname">
                        </mat-form-field>
                    </div>
                    <div class="row">
                        <mat-form-field appearance="outline">
                            <mat-label> Last Name </mat-label>
                            <input matInput type="text" placeholder="Your Last Name" formControlName="lname">
                        </mat-form-field>
                    </div>
                    <div class="row">
                        <mat-form-field appearance="outline">
                            <mat-label for="phone">Your Phone number</mat-label>
                            <input matInput type="text" id="phone" placeholder="Phone number" formControlName="phone" maxlength=10>
                        </mat-form-field>
                    </div>
                    <div class="row">
                        <mat-form-field appearance="outline">
                            <mat-label for="email"> Your Email </mat-label>
                            <input matInput type="email" id="email" placeholder="Your Email"
                                formControlName="email"><br>
                        </mat-form-field>
                    </div>
                    
                    <div class="centerbutton">
                        <button class="btn btn-success " type="button" (click)="sendOTP()" [disabled]="!firstFormGroup.valid" >Start Hiring
                            Process</button>
                            <!--   -->
                    </div>
                </div>

                <div class="col-lg-6 htw">
                    <img src="assets/therapist/first-view.png" class="first_t">
                </div>
            </form>
        </div>


        <div class="form" *ngIf="page == 2">
            <div class="col-md-6 sec_form">
                <form [formGroup]="secondFormGroup">
                    <h1 class="head_h">
                        Please enter One Time Code sent to your Phone and Email
                    </h1>
                    <div class="col-12">
                        <!-- class="form-group" -->
                        <div class="row otp_pass">
                            <mat-form-field appearance="outline">
                                <mat-label for="pass">Please enter One Time Code sent to your Phone and Email</mat-label>
                                <input matInput type="password" id="pass" formControlName="otp" minlength=6 maxlength=6>
                            </mat-form-field>
                        </div>
                        <div class="row alert alert-warning" *ngIf="wrongOTP != '' " style="width: 95%;">
                            <p class="alert body">
                                {{ wrongOTP }}
                            </p>
                        </div>
                        <div class="centerbutton">
                            <button class="btn btn-light" type="button" (click)="prevPage()">Previous</button>
                            <button class="btn btn-success ml-5" type="button" color="primary" [disabled]="!secondFormGroup.valid" (click)="validateOTP()">Next</button>
                        </div>
                    </div>
                </form>
            </div>
            <div class="col-md-6 htw">
                <img src="assets/therapist/Passworf.png" class="first_t2">
            </div>
        </div>

        <div class="form" *ngIf="page == 3">
            <!-- 3 -->
            <div class="col-md-6 trd box_sadow">
                <form [formGroup]="thirdPointZeroFormGroup">
                    <div>
                        <h1 class="head_h" style="text-align: left; margin-bottom: 30px;">I am</h1>
                        <div class="radiodiv">
                            <div class="form-check " *ngFor="let emp of employeeTypes">
                                <input id="{{ emp.ActivelicenseId }}" type="radio" class="form-check-input" name="employeeSubType"
                                    formControlName="employeeSubType" value="{{ emp.ActivelicenseId }}">
                                <label for="{{ emp.ActivelicenseId }}" class="form-check-label">{{ emp.LicenseName }}</label>
                            </div>
                            <div *ngIf="thirdPointZeroFormGroup.value.employeeSubType == '8'">
                                <mat-form-field appearance="outline">
                                    <mat-label>Please specify *</mat-label>
                                    <input type="text" matInput formControlName="other">
                                    <mat-error>Must be more than 3 characters</mat-error>
                                </mat-form-field>
                            </div>

                        </div>
                       <div class="centerbutton">
                            <button class="btn btn-success btn btn-light" type="button"
                                (click)="prevPage(2)">Previous</button>
                            <button class="btn btn-success " type="button" color="primary"
                                (click)="nextPage(3.01)"   [disabled]="!validateEmployeeSubtype()">Next</button>
                       </div>
                            <!-- -->
                    </div>
                </form>
            </div>
            <div class="col-md-6 htw"> <img src="assets/therapist/select-3.png"
                    class="first_t3"></div>
        </div>

        <div class="form trd" *ngIf="page == 3.01">
            <!-- 3.01 -->
            <div class="col-md-6 box_sadow">
                <form [formGroup]="thirdFormGroup">
                    <div>
                        <h1 class="head_h" style="text-align: left;">Are you looking for a Salaried/Hourly (W2) position
                            or would you <br> like to join our network as an Independent Provider?</h1>
                        <div class="radiodiv">
                            <div class="form-check ">
                                <input type="radio" class="form-check-input hourly" id="1" value="1" name="employeeType"
                                    formControlName="employeeType">
                                <label for="1" class="form-check-label ">W2 - Salaried/Hourly</label>
                            </div>
                            <div class="form-check ">
                                <input type="radio" class="form-check-input contractor" name="employeeType" value="2"
                                    id="cont" formControlName="employeeType">
                                <label for="cont" class="form-check-label ">Contractor - Independent Provider</label>
                            </div>
                        </div>
                        <div class="centerbutton">
                            <button class="btn btn-success btn btn-light" type="button"
                                (click)="prevPage(3)">Previous</button> 
                                <!-- 3 -->
                            <button class="btn btn-success " type="button" color="primary"
                                (click)="navigateToStates()" [disabled]="!thirdFormGroup.valid" >Next</button>
                        </div>
                            <!--  -->
                            <!-- 3.1 -->
                    </div>
                </form>
            </div>

            <div class="col-md-6 htw">

                <img  *ngIf="thirdFormGroup.value.employeeType == ''" src="assets/therapist/select-4.png" class="first_t2 vdeo_img">
                <div>
                    <div class="hourly_vido" *ngIf="thirdFormGroup.value.employeeType == '1'">
                            <h1>Why Join Us?</h1>
                            <iframe width="480" height="270" src="https://www.youtube.com/embed/PrMfyo6VnVQ" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    </div>

                   
                        <div  class="contractor_vido" *ngIf="thirdFormGroup.value.employeeType == '2'">
                            <h1>Why Join Our Network?</h1>
                            <iframe width="480" height="270" src="https://www.youtube.com/embed/PrMfyo6VnVQ"
                                title="YouTube video player" frameborder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowfullscreen></iframe>
                          
                    </div>
                    

                </div>
            </div>
        </div>



        <div class="form" *ngIf="page == 3.1">
            <!-- 3.1 -->
            <div class="col-md-6 drp_dwn box_sadow">
                <form [formGroup]="thirdPointOneFormGroup">
                    <h1 class="head_h" style="text-align: left; margin-bottom: 30px;">
                        Select the States where you hold an active license(s)?
                    </h1>
                    <div class="form-group" style="margin-bottom: 15px;width: 90%;">
                        <div class="row">
                            <mat-form-field appearance="fill">
                                <mat-label>
                                    Select the states that you are licensed in?
                                </mat-label>
                                <mat-select multiple formControlName="states">
                                    <mat-option *ngFor="let state of availableStates" [value]="state[0]">
                                        {{ state[1] }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>

                    </div>
                    <div class="centerbutton">
                        <button type="button" class="btn btn-light " (click)="prevPage(3.01)">Previous</button>
                        <button type="button" class="btn btn-success "
                            (click)="generateStates(thirdFormGroup.value.employeeType == '1'? 4 : 9)" [disabled]="!thirdPointOneFormGroup.valid">Next</button>

                    </div>
                </form>
            </div>
            <!-- <div class="col-md-6"><img src="https://slp.offerorchard.com/wp-content/uploads/2022/06/drop.png" class="first_t2"></div> -->
        </div>

        <div class="form" *ngIf="page == 4 || page == 9">
            
            <div class="col-md-8 col-sm-3 lic_slct box_sadow">
            <form [formGroup]="fourthFormGroup">

                <table class="table">
                    <thead>
                        <tr>
                            <th>State</th>
                            <th>License Number</th>
                            <th>License Expiry</th>
                        </tr>
                    </thead>

                    <tbody formArrayName="states">
                        <tr *ngFor="let iterrow of states().controls; let i = index; let l = last;"
                            [formGroupName]="i">
                            <td>
                                <mat-form-field appearance="outline">
                                    <mat-label>State</mat-label>
                                    <input type="text" matInput formControlName="licensestate">
                                </mat-form-field>
                            </td>
                            <td>
                                <mat-form-field appearance="outline">
                                    <mat-label>License Number</mat-label>
                                    <input type="text" matInput formControlName="licensenumber">
                                    <mat-error style="color: red">Required Field</mat-error>
                                </mat-form-field>
                            </td>
                            <td>
                                <mat-form-field appearance="outline">
                                    <mat-label>License Expiry Date</mat-label>
                                    <input matInput [min]="todayDate" [matDatepicker]="picker" formControlName="expirydate">
                                    <mat-hint>MM/DD/YYYY <span><p *ngIf="states().controls[i].hasError('noExpiry')"> • Mandoatory Field</p></span></mat-hint>
                                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                    <mat-datepicker #picker></mat-datepicker>
                                    <mat-error style="color: red">Required Field</mat-error>
                                </mat-form-field>
                            </td>

                        </tr>
                    </tbody>
                </table><br>

                <br>
                <br>
                <div class="centerbutton">
                    <button class="btn btn-success btn btn-light" type="button"
                        (click)="clearStateTable(3.1)">Previous</button>
                    <button class="btn btn-success " type="button" (click)="validateLicenses(thirdFormGroup.value.employeeType == '1' ? 3.02 : 9.01)" [disabled]="!fourthFormGroup.valid" color="primary">Next</button>
                    <!--    -->
                    <button class="btn  btn-outline-success stt_skp" (click)="nextPage(thirdFormGroup.value.employeeType == '1' ? 3.02 : 9.01)"  > SKIP</button>

                </div>
            </form>
        </div>

            <!-- <div class="col-md-4"><img src="assets/therapist/lisence.jpg" class="first_t2" style="position: relative; left: 80px;"></div> -->

        </div>

        <div class="form" *ngIf="page == 3.02">
            <div class="col-md-6 drp_dwn box_sadow">
                <form [formGroup]="threePointZerotwo">
                    <h1 class="head_h" style="text-align: left; margin-bottom: 30px;">
                        Which state do you Physically reside in?
                    </h1>
                    <div class="form-group" style="margin-bottom: 15px;width: 90%;">
                        <div class="row">
                            <mat-form-field appearance="fill">
                                <mat-label>
                                    Select the states that you Physically reside in?
                                </mat-label>
                                <mat-select formControlName="reside">
                                    <mat-option value="ou">
                                        Outside Of United States
                                    </mat-option>
                                    <mat-option *ngFor="let state of availableStates" [value]="state[0]">
                                        {{ state[1] }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>

                    </div>
                    <div class="centerbutton">
                        <button type="button" class="btn btn-light " (click)="prevPage(needLicenses.indexOf(thirdPointZeroFormGroup.value.employeeSubType) != -1 ? 4 : 3.01 )">Previous</button>
                        <button type="button" class="btn btn-success" (click)="nextPage(5)" [disabled]="!threePointZerotwo.valid">Next</button>
                        <!--  -->

                    </div>
                </form>
            </div>
            
                <div class="col-md-6 htw"> <img src="assets/therapist/address.png" class="first_t2" style="position: relative;left: 80px;float: none;">  
            </div>
        </div>


        <div *ngIf="page == 5" class="form">
            <!-- 5 -->
           <div class="col-md-6 box_sadow">
                <form [formGroup]="fifthFormGroup" >
                    <h1>Would you be open to obtaining licensure in other states?</h1>
    
                    <div class="form-group">
                        <div class="form-check ">
                            <input type="radio" id="yeslicesnse" name="otherlicense" formControlName="otherlicense"
                                class="form-check-input" value="true">
                            <label for="yeslicesnse" class="form-check-label">Yes</label>
                        </div>
                        <div class="form-check ">
                            <input type="radio" id="nolicesnse" formControlName="otherlicense" name="otherlicense"
                                class="form-check-input" value="false">
                            <label for="nolicesnse" class="form-check-label">No</label>
                        </div>
                        <br>
                        <h1>Which languages are you fluent in?</h1>
                        <mat-form-field style="width: auto;" appearance="fill">
                            <mat-label>Which languages are you fluent in?</mat-label>
                            <mat-select multiple="" formControlName="languages">
                                <mat-option *ngFor="let lang of supportedLanguages" [value]="lang[1]">{{ lang[0] }}
                                </mat-option>
    
                            </mat-select>
                        </mat-form-field>
                        <br>
    
                        <h1>Which age groups are you most comfortable with? </h1>

                        <div class="form-check ">
                            <input type="checkbox" id="ei" formControlName="ei" class="form-check-input"
                                name="ei" >
                            <label for="ei" class="form-check-label">0-3 Early Intervention</label>
                        </div>
    
                        <div class="form-check ">
                            <input type="checkbox" id="ps" formControlName="ps" class="form-check-input"
                                name="ps" value="2">
                            <label for="ps" class="form-check-label">3 - 5 Pre School</label>
                        </div>
    
                        <div class="form-check ">
                            <input type="checkbox" id="sa" formControlName="sa" class="form-check-input"
                                name="sa" value="3">
                            <label for="sa" class="form-check-label">5 - 18 School Age</label>
                        </div>
    
                        <div class="form-check ">
                            <input type="checkbox" id="ad" formControlName="ad" class="form-check-input"
                                name="ad" value="4">
                            <label for="ad" class="form-check-label">Adults</label>
                        </div>
    
                        <div class="form-check ">
                            <input type="checkbox" id="gr" formControlName="gr" class="form-check-input"
                                name="gr" value="5">
                            <label for="gr" class="form-check-label">Geriatrics</label>
                        </div>

                        <div class="centerbutton">
                            <button class="btn btn-success btn btn-light" type="button"
                                (click)="prevPage(3.02)">Previous</button>
                            <button class="btn btn-success htw" type="button" (click)="nextPage(8)"  [disabled]="!(fifthFormGroup.valid && checkAgeGroup())" >Next</button>
                            <!--  -->
                        </div>
                    </div>
                </form>
           </div>
        
            <div class="col-md-6"> <img src="assets/therapist/form2.png" class="first_t2" style="position: relative;left: 80px;float: none;">
             </div>

        </div>
        
        <div class="form" *ngIf="page == 8">
            <!-- 8 -->

            <form [formGroup]="eightFormGroup" >
                <div class="form-group">
                    <h1 for="resume" class="form-label">Please upload your resume?</h1>
                    <div><input (change)="onChange($event)" type="file" id="resume" class="form-control form-control-sm" formControlName="resume"
                            name="resume" accept=".pdf, .docx, .doc"></div><br><br>
                    <div class="centerbutton">
                        <button class="btn btn-light" type="button" (click)="prevPage(5)">Previous</button>
                        <button class="btn btn-success " type="button"  (click)="onUpload(6)">Next</button>
                        <button class="btn btn-success stt_skp" type="button" (click)="nextPage(6)">Skip</button>
                    </div>
                </div>
            </form>
        </div>

    
            <div class="form" *ngIf="page == 6">
                <!-- 6 -->
                <div class="col-md-4 box_sadow">
                    <form [formGroup]="sixthFormGroup" >
                        <h1>How many hours per week are you available to work?</h1>
                        <div class="form-check">
                            <input type="radio" class="form-check-input" value="40" formControlName="time" id="ft40s">
                            <label for="ft40s">Full Time 40 Hours Salaried</label>
                        </div>

                        <div class="form-check">
                            <input type="radio" class="form-check-input" value="30" formControlName="time" id="ft30s">
                            <label for="ft30s">Full Time 30 Hours Salaried</label>
                        </div>

                        <div class="form-check">
                            <input type="radio" class="form-check-input" value="20" formControlName="time" id="2030hw">
                            <label for="2030hw">20 - 30 Hours Per Week</label>
                        </div>

                        <div class="form-check">
                            <input type="radio" class="form-check-input" value="12" formControlName="time" id="1220hw">
                            <label for="1220hw">12 - 20 Hours Per Week</label>
                        </div>

                        
                    </form>
                <br>
                <form [formGroup]="seventhFormGroup" >
                    <div class="form-group">
                        <h1>Which hours of the day do you prefer? </h1>
                        <div class="form-check ">
                            <input type="checkbox" id="mrng" formControlName="morning" class="form-check-input" name="morning"
                                value="1">
                            <label for="mrng" class="form-check-label">Morning</label>
                        </div>
    
                        <div class="form-check ">
                            <input type="checkbox" formControlName="afternoon" id="noon" class="form-check-input" name="afternoon"
                                value="2">
                            <label for="noon" class="form-check-label">Afternoon</label>
                        </div>
    
                        <div class="form-check ">
                            <input type="checkbox" id="eve" formControlName="evening" class="form-check-input" name="evening"
                                value="3">
                            <label for="eve" class="form-check-label">Evening</label>
    
                        </div>

    
                        <div class="centerbutton">
                            <button class="btn btn-success btn btn-light" type="button"
                                (click)="prevPage(8)">Previous</button>
                            <button class="btn btn-success " type="button" (click)="saveTherapistDetails(8.1)"  [disabled]="!(sixthFormGroup.valid && seventhFormGroup.valid && checkDay())">Submit</button>
                            <!--  -->
                        </div>
                    </div>
                </form>
            </div>
            <div class="col-md-6"><img src="assets/therapist/form2.png" class="first_t2"></div>
        </div>


        <div *ngIf="page == 8.1">
            <h1 style="text-align: center;font-size: 24px;font-weight: 600;font-family: 'Poppins';margin-bottom: 40px;"> Thank you for submitting your application!</h1>
                
                <h1 style="text-align: center;">To schedule a meeting with the recruiter please click below.</h1>
            
                <div class="centerbutton">
                    <button class="btn btn-success"><a href="https://go.oncehub.com/Daniel35" (click)="clickedSubmit = true" 
                        style="text-decoration: none;">Talk to Recruiter</a></button>                    
                </div>
        </div>
        
        <div class="form" *ngIf="page == 9.01">
            <div class="col-md-6 drp_dwn box_sadow">
                <form [formGroup]="threePointZerotwo">
                    <h1 class="head_h" style="text-align: left; margin-bottom: 30px;">
                        Which state do you physically reside in?
                    </h1>
                    <div class="form-group" style="margin-bottom: 15px;width: 90%;">
                        <div class="row">
                            <mat-form-field appearance="fill">
                                <mat-label>
                                    Select the states that you Physically reside in?
                                </mat-label>
                                <mat-select formControlName="reside">
                                    <mat-option value="ou">
                                        Outside Of United States
                                    </mat-option>
                                    <mat-option *ngFor="let state of availableStates" [value]="state[0]">
                                        {{ state[1] }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>

                    </div>
                    <div class="centerbutton">
                        <button type="button" class="btn btn-light " (click)="prevPage(thirdPointZeroFormGroup.value.employeeSubType == '1' || thirdPointZeroFormGroup.value.employeeSubType == '2' ? 9 : 3.01 )">Previous</button>
                        <button type="button" class="btn btn-success "
                            (click)="nextPage(10)"  [disabled]="!threePointZerotwo.valid">Next</button>
                            <!--  -->

                    </div>
                </form>
            </div>
        </div>


        <div class="form phy_loc" *ngIf="page == 10">
        <!-- 10 -->
        <div  class="col-md-6 rdo_cust_btn box_sadow">
            <form [formGroup]="Form10Group">
                <div class="form-group ">
                    <h1 class="head_h" style="text-align: left;">
                        Do you have a Physical Location where you treat of?
                    </h1>
                    <div class="form-check">
                        <input type="radio" id="yes" formControlName="haveLocation" class="form-check-input"
                            name="haveLocation" value="true">
                        <label for="yes" class="form-check-label">Yes</label>
                    </div>
                    <div class="form-check">
                        <input type="radio" id="no" formControlName="haveLocation" class="form-check-input"
                            name="haveLocation" value="false">
                        <label for="no" class="form-check-label">No</label>
                    </div>
                    <div class="locations" *ngIf="Form10Group.value.haveLocation == 'true'">
                        <mat-form-field appearance="outline">
                            <input matInput type="text" id="primary" formControlName="primaryLocation"
                                name="primaryLocation">
                            <mat-label for="primary">Your Primary Location Address</mat-label>
                        </mat-form-field>
                        <br>
                        <mat-form-field appearance="outline">
                            <input matInput type="text" id="al1" formControlName="AdditionalLocation1"
                                name="primaryLocation">
                            <mat-label for="al1">Other Location Address 1 (Optional)</mat-label>
                        </mat-form-field>
                        <br>
                        <mat-form-field appearance="outline">
                            <input matInput type="text" id="al2" formControlName="AdditionalLocation2"
                                name="primaryLocation">
                            <mat-label for="al2">Other Location Address 2 (Optional)</mat-label>
                        </mat-form-field>
                    </div>

                    <div>
                        <button class="btn btn-success btn btn-light" type="button"
                            (click)="prevPage(9.01)">Previous</button>
                        <button class="btn btn-success " type="button" (click)="saveTherapistDetails(11)" [disabled]="!(Form10Group.valid && validatePrimaryLocations())" >Submit</button>
                        <!--  -->
                    </div>
                </div>
            </form>
        </div>
            <!-- <div  class="col-md-6">
                <img src="https://slp.offerorchard.com/wp-content/uploads/2022/06/12127-ai.png"></div> -->
        </div>

        <div *ngIf="page == 11">
            <div>
                <h1 *ngIf="!clickedSubmit" style="text-align: center;font-size: 24px;font-weight: 600;font-family: 'Poppins';margin-bottom: 40px;">
                     Thank you for submitting your application!<br><br>
                      </h1>
                      <h2 style="text-align: center;font-size: 20px;font-weight: 600;font-family: 'Poppins';margin-bottom: 40px;">We have created your access for the SLP-tele system. Your username and password is your email address.</h2>
            </div>
            <div class="centerbutton">
                <button class="btn btn-success" type="button" [hidden]="clickedSubmit"><a href="https://app.slptele.com" style="text-decoration: none;">Login</a></button>

            </div> <br><br>
            <div class="centerbutton">
                <h1 style="text-align: center;font-size: 24px;font-weight: 600;font-family: 'Poppins';margin-bottom: 10px;">
                    To schedule a meeting with the onboarding specialist please click below.
                </h1>
                <button class="btn btn-success" style="width: 260px;"><a href="https://go.oncehub.com/Daniel35" (click)="clickedSubmit = true;" style="text-decoration: none;">Talk to an Onboarding Specialist</a></button>

            </div>
        </div>
        <div *ngIf="page == 12">
            <div>
                <h1  style="text-align: center;font-size: 24px;font-weight: 600;font-family: 'Poppins';margin-bottom: 40px;">We already have a profile for given phone number. <br> To login, please click below. <br> </h1>
                <div class="centerbutton">
                    <button class="btn btn-success" type="button" [hidden]="clickedSubmit"><a href="https://app.slptele.com" style="text-decoration: none;">Login</a></button>
                </div>
                <h1 style="text-align: center;font-size: 24px;font-weight: 600;font-family: 'Poppins';margin-bottom: 40px;"> Your username and password is your email address</h1>
            </div>
        </div>
    </div>
</div>








 
