import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { environment } from 'src/environments/environment';
import { ContentService, AreaOfNeeds } from '../content.service';
import { TherapistData } from '../search-therapist/search-therapist.component';
import { jsonAON } from '../search-therapist/search-therapist.component';


@Component({
  selector: 'app-thprofile',
  templateUrl: './thprofile.component.html',
  styleUrls: ['./thprofile.component.scss']
})
export class ThprofileComponent implements OnInit {
  title = "Therapist Profile"
  constructor(
    private rl: ActivatedRoute,
    private spinner: NgxSpinnerService,
    private http: HttpClient,
    private content: ContentService,
    private sanitizer: DomSanitizer
    ) { }
  therapistsData: TherapistData[] = []
  window = window
  therapistName: string = "";
  therapistData?: any
  avatarURL = environment.avatarURL
  areaOfNeeds!: AreaOfNeeds
  json_data!: jsonAON[]
  prev = 0
  dispayMainCat = true
  profileName!: string
  profileHtml!: SafeHtml


  getState(value: string) {
    // // console.log(environment.statesInKeyShortCode.find(x => x.value == value)?.name)
    return environment.statesInKeyShortCode.find(x => x.value == value)?.name
  }

  convertAONIntoJson() {
    var d: jsonAON[] = []
    this.areaOfNeeds.ListResult.forEach(
      element => {
        var SpecialtiesName = element.SpecialtiesName
        element.SubSpecialties.forEach(
          subelement => {
            var temp: any = {}
            temp.SubSpecialtiesId = subelement.SubSpecialtiesId
            temp.SpecialtiesId = subelement.SpecialtiesId
            temp.SubSpecialtiesName = subelement.SubSpecialtiesName
            temp.SpecialtiesName = SpecialtiesName
            d.push(temp)
          }
        )
      }
    )
    return d
  }

  ngOnInit(): void {
    this.rl.params.subscribe(params => {
      var search = params['search']
      this.profileName = search
      this.http.get(`${environment.DocURL}${search}.html`,{responseType:'text'}).subscribe(res=>{
        this.profileHtml = this.sanitizer.bypassSecurityTrustHtml(res);
      })
    })
  }

  getAgeGroup(code: string) {
    var data: any = {
      "1": "0-3 Early Intervention",
      "2": "3 - 5 Pre School",
      "3": "5 - 18 School Age",
      "4": "Adults",
      "5": "Geriatrics"
    }

    return data[code]
  }

  getAreaOfNeedsString(data: string, change = true) {  
    // // console.log(this.json_data)
    if (this.json_data != undefined){
    var temp = this.json_data!.find(x => x.SubSpecialtiesId == Number(data))
    if (!(temp == undefined)){
      if (change) this.prev = temp!.SpecialtiesId
      return temp
    }}
    return 
  }

  getTime(code: string) {
    var data: any = {
      "1" : "Morning",
      "2" : "Afternoon",
      "3" : "Evening"
    }
    return data[code]
  }


}
