import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NewClientComponent } from './new-client/new-client.component';
import { NewTherapistComponent } from './new-therapist/new-therapist.component';
import { RedirectComponent } from './redirect/redirect.component';
import { SearchTherapistComponent } from './search-therapist/search-therapist.component';
import { ThprofileComponent } from './thprofile/thprofile.component';


const routes: Routes = [
  {'path' : 'new-provider', component: NewTherapistComponent},
  {'path' : 'new-client', component: NewClientComponent},
  {"path" : "provider-profile/:search", component: ThprofileComponent},
  {"path" : "provider-profile", component: SearchTherapistComponent},
  {"path" : "search-provider", component: SearchTherapistComponent},
  {"path" : "", component: RedirectComponent},

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }