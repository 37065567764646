import { AbstractControl, ValidationErrors } from '@angular/forms';  
    
export class WhiteSpaceValidator {  

    static cannotContainOnlySpace(control: AbstractControl) : ValidationErrors | null {  

        if(control.value==null)
        {
            return null;
        }
        if((control.value as string).trim().length == 0){  
            return {cannotContainOnlySpace: true}  
        }  
    
        return null;  
    }  
}  