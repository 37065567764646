import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import * as $ from 'jquery'

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit{
  title = 'Forms SLP-Tele';

  forms = environment.forms
  environment = environment.website

  ngOnInit(): void {

    jQuery(function() {

      $(document).on('ready', function() { 
        $("#menu-toggler").on('click', function() {
          toggleBodyClass("menu-active");
        });
      
        function toggleBodyClass(className: any) {
          document.body.classList.toggle(className);
        }
      })
    })

   
  }


  getScreenType() {
    // // console.log(navigator.user)
  }

}
