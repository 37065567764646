import { NgModule, CUSTOM_ELEMENTS_SCHEMA  } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { MatSliderModule } from '@angular/material/slider';
import { MatSelectModule} from '@angular/material/select'
import { MatInputModule } from "@angular/material/input"
import { MatFormFieldModule } from "@angular/material/form-field"
import { AppRoutingModule } from './app-routing.module';
import { MatButtonModule } from "@angular/material/button"
import { AppComponent } from './app.component';
import { NewClientComponent } from './new-client/new-client.component';
import { NewTherapistComponent } from './new-therapist/new-therapist.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { MatIconModule } from '@angular/material/icon';
import { MatRadioModule } from '@angular/material/radio'
import { MatTableModule, MatTableDataSource } from '@angular/material/table';
import { FormsModule } from '@angular/forms';
import { MatFileUploadModule } from 'angular-material-fileupload';
import { MatCheckboxModule } from '@angular/material/checkbox'
import { FlexLayoutModule } from '@angular/flex-layout';
import { SearchPipe } from './search.pipe';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { MatDatepickerModule } from '@angular/material/datepicker'
import { MatNativeDateModule } from '@angular/material/core';
import { FooterComponent } from './footer/footer.component';
import { HeaderComponent } from './header/header.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner'
import { NgxSpinnerModule } from "ngx-spinner";
import { DatePipe } from '@angular/common';
import { ThprofileComponent } from './thprofile/thprofile.component';
import { RouterLink } from '@angular/router';
import {MatCardModule} from '@angular/material/card';
import  { MatAutocompleteModule } from '@angular/material/autocomplete';
import { SearchTherapistComponent } from './search-therapist/search-therapist.component'
import { MatExpansionModule } from '@angular/material/expansion';
import { MatTreeModule } from '@angular/material/tree'
import { MatTooltipModule } from '@angular/material/tooltip'
import { ToastrModule } from 'ngx-toastr';
import { MatToolbarModule } from '@angular/material/toolbar'
import { MatSidenavModule } from '@angular/material/sidenav'
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { NewHeaderComponent } from './new-header/new-header.component';
import { RedirectComponent } from './redirect/redirect.component'
// import { MatSidenavModule } from '@angular/material/sidenav'

@NgModule({
  declarations: [
    AppComponent,
    NewClientComponent,
    NewTherapistComponent,
    SearchPipe,
    FooterComponent,
    HeaderComponent,
    ThprofileComponent,
    SearchTherapistComponent,
    NewHeaderComponent,
    RedirectComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    MatSliderModule,
    MatFormFieldModule,
    MatSelectModule,
    MatInputModule,
    MatButtonModule,
    HttpClientModule,
    MatIconModule,
    MatRadioModule,
    MatTableModule,
    FormsModule,
    MatFileUploadModule,
    MatCheckboxModule,
    FlexLayoutModule,
    Ng2SearchPipeModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatProgressSpinnerModule,
    BrowserAnimationsModule,
    NgxSpinnerModule,
    MatCardModule,
    MatAutocompleteModule,
    MatExpansionModule,
    MatTreeModule,
    MatTooltipModule,
    ToastrModule.forRoot(),
    MatToolbarModule,
    MatSidenavModule,
    MatListModule,
    MatMenuModule
  ],
  providers: [DatePipe, RouterLink],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule { }
