import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Form, FormArray, FormBuilder, FormControl, FormGroup, Validators, NgForm, FormGroupDirective } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { ErrorStateMatcher } from '@angular/material/core';
import { NgxSpinnerService } from "ngx-spinner";
import { OtpserviceService } from '../otpservice.service';
import { ContentService } from '../content.service';
import { ActivatedRoute } from '@angular/router';



@Component({
  selector: 'app-new-therapist',
  templateUrl: './new-therapist.component.html',
  styleUrls: ['./new-therapist.component.scss'],
})



export class NewTherapistComponent implements OnInit {
	constructor(
		private fb: FormBuilder, 
		private http: HttpClient, 
		private spinner: NgxSpinnerService, 
		private otpservice: OtpserviceService,
		private content: ContentService,
		private rl: ActivatedRoute
		) {}
	TherapistEnquiryID!: string
	title = "Therapist On Boarding"
	todayDate = new Date()
	firstFormGroup!: FormGroup;
  	secondFormGroup!: FormGroup;
  	thirdFormGroup!: FormGroup;
	thirdPointZeroFormGroup!: FormGroup;
	threePointZerotwo!: FormGroup;
	thirdPointOneFormGroup!: FormGroup;
	fourthFormGroup!: FormGroup;
	fifthFormGroup!: FormGroup;
	sixthFormGroup!: FormGroup;
	seventhFormGroup!: FormGroup;
	eightFormGroup!: FormGroup;
	ninthFormGroup!: FormGroup;
	Form10Group!: FormGroup;
	Form11Group!: FormGroup;
	Form12Group!: FormGroup;
	Form13Group!: FormGroup;
	Form14Group!: FormGroup;
	Form15Group!: FormGroup;
	Form16Group!: FormGroup;
	Form17Group!: FormGroup;
	Form18Group!: FormGroup;
	Form19Group!: FormGroup;
	Form20Group!: FormGroup;
	Form21Group!: FormGroup;
	Form22Group!: FormGroup;
	Form23Group!: FormGroup;
	Form24Group!: FormGroup;
	stateErrors: boolean[] = []
	disableStateInputField  = true
	clickedSubmit = false
	wrongOTP = ""
    file!: File; // Variable to store file
	ResumeData!: ArrayBuffer
	availableStates: string[][] = environment.statesList
	selectedStatesList: SelectedStatesList[] = []
	selectedStateListColumn: string[] = ["state", "licensenumber", "expirydate"]
	page: number = 1;
	languagesAvailable: string[][] = environment.languages
	totalPlans: string[][] = [
		["l", "Lite"],
		["b", "Basic"],
		["s", "Standard (Money Back Guarantee)"]
	]
	networkBenifitsList: string[][] = [
		["a", "A Network Benifit"],
		["b", "B Network Benifit"]
	]
	supportedLanguages = environment.supportedLanguages
	areasOfSpecialities: string[][] = [
		["Area1", "a1"],
		["Area2", "a2"]
	]
	ageGroupsValues = [
		{
			"name" : "0-3 Early Intervention",
			"value" : 1,
			"checked" : false
		},
		{
			"name" : "3 - 5 Pre School",
			"value" : 2,
			"checked" : false
		},
		{
			"name" : "5 - 18 School Age",
			"value" : 3,
			"checked" : false
		},
		{
			"name" : "Adults",
			"value" : 4,
			"checked" : false
		},
		{
			"name" : "Geriatrics",
			"value" : 5,
			"checked" : false
		},
	]
	otpVerified = false;
	employeeTypes: EmployeeType[] = []
	needLicenses: number[] = []


	matcher = new MyErrorStateMatcher();

	sendOTP() {
		this.spinner.show()

				
		this.http.get(`${environment.server_url}api/Network/TherapistExists/?mobileNumber=${this.firstFormGroup.value.phone}&emailId=${this.firstFormGroup.value.email}`).subscribe(
			(data: any) => {
				if (data['MessageDetail'] == 'Therapist Already Exist'){
					this.content.showWarning("We already have a profile for given phone number.", "Profile already exists")
					this.spinner.hide()
					this.page = 12
					return 
				}
				else {
					if (this.otpVerified) {
						this.spinner.hide()
						this.page++;
						return 
					}
					var savedata = {
						FirstName: this.firstFormGroup.value.fname,
						LastName: this.firstFormGroup.value.lname,
						Mobilenumber: this.firstFormGroup.value.phone,
						EmailID: this.firstFormGroup.value.email,
						RequestSource: "2"
					}
					this.http.post(`${environment.server_url}api/Network/SaveTherapistDetails`, savedata).subscribe(
						(saveset: any) => {
							// console.log(saveset)
							this.TherapistEnquiryID = saveset['Result']
						}
					)
					this.otpservice.sendOTP(
						this.firstFormGroup.value.phone,
						this.firstFormGroup.value.email
					).subscribe(
						data => {
							console.log(data)
							this.content.showSucess("One Time Code sent successfully", "One Time Code sent!")
							this.page ++
							this.spinner.hide()
						},
						(error: any) => {
							this.content.showError("There was an some unexpected error. Please try after some time", "Please try after some time")
							this.spinner.hide()
						}
					);
				}
			},
			(error: any) => {
				console.log("Error")
				this.content.showError("There was an some unexpected error. Please try after some time", "Please try after some time")
				this.spinner.hide()
			}
		)		
		
	}

	validateOTP(){		
		this.spinner.show()
		if (this.otpVerified) {
			this.spinner.hide()
			this.page ++
			return 
		}
	
		// this.page++
		this.otpservice.validateOTP(
			this.firstFormGroup.value.phone,
			this.secondFormGroup.value.otp
			).subscribe(
			(value: any) => {
			// // console.log(value)
			if (value['Result']) {
				this.content.showSucess("One Time Code Verified successfully", "Verification Successfull")
				this.page ++;
				this.spinner.hide()
				this.otpVerified = true;
			}
			else{
				this.content.showError("You've entered an Invalid One Time Code. Please try again", "Invalid One Time Code")
				this.wrongOTP = "Invalid One Time Code"
				this.spinner.hide()
			}
			
			},
			(error: any) => {
				this.content.showError("There was an some unexpected error. Please try after some time", "Please try after some time")
				this.spinner.hide()
			}	
			)
	}

	statesRows(state: string, index: number): FormGroup {
		return this.fb.group({
			licensestate: this.fb.control({value: state, disabled: true}),
			licensenumber: ["", Validators.maxLength(10)],
			expirydate: ['', ],
		}, { validator: this.checkExpiryAndNumber })
	}

	checkExpiryAndNumber(group: FormGroup): any{
		// // console.log(group.value)
		if ((group.value.licensenumber != "" && group.value.expirydate == "") || (group.value.licensenumber == "" && group.value.expirydate != "") )  {
			return {
				noExpiry: true
			}
		}
		return null
	}

	states() {
		return this.fourthFormGroup.get("states") as FormArray;
	}

	get getFormIters() {
		return this.fourthFormGroup.controls['states'] as FormArray;
	}

	validateEmployeeSubtype(): boolean{
		if (this.thirdPointZeroFormGroup.value.employeeSubType == 'other'){
			if (this.thirdPointZeroFormGroup.value.other.length > 1){
				return true
			}
			else {
				return false
			}
		}
		else if (this.thirdPointZeroFormGroup.value.employeeSubType == "") {
			return false
		}
		else {
			return true
		}
	}


	validateLicenses(page: number): boolean{

		var array: [] = this.fourthFormGroup.value.states
		var error: boolean = false
		for (let index = 0; index < array.length; index++) {
			if (array[index]['licensenumber'] != "" && array[index]['expirydate'] == ""){
				error = true
				this.stateErrors[index] = true
			}
			else {
				this.stateErrors[index] = false
			}


		}
		if (!error){
			this.page = page;
		}
		return true
	}
	validatePrimaryLocations(){
		var ten = this.Form10Group.value
		if (ten.haveLocation == "true") {
			if ((ten.primaryLocation != "" && ten.primaryLocation != null) && (ten.AdditionalLocation1 == "" || ten.AdditionalLocation1 == "" ||  ten.AdditionalLocation1 != "" || ten.AdditionalLocation1 != "")) {
				return true
			}
			else {
				return false
			}
		}
		return true
	}

	getActiveLicenseDetails() {
		this.http.get<any>(`${environment.server_url}api/Network/GetTherapistlicenseTypes`).subscribe(
			(orginal: any) => {
				var data: EmployeeType[] = orginal['ListResult']
				console.log(data)
				for (var i = 0; i < data.length; i++) {
					if (data[i].NeedLicense) {
						this.needLicenses.push(data[i].ActivelicenseId)
					}
					this.employeeTypes.push(data[i])
				}
				console.log(this.employeeTypes)
			}
		)
	}

  	ngOnInit(): void {

		//First form 
		this.firstFormGroup = this.fb.group({
		fname: ['', [Validators.required, Validators.pattern(/[A-Za-z\s]/)]],
		lname: ['', [Validators.required, Validators.pattern(/[A-Za-z\s]/)]],

		// lastName: [''],
		phone: ['', [Validators.required, Validators.pattern(/^\d{10}$/)]],
		email: ['', [Validators.required, Validators.email]]
		})

		//Second form
		this.secondFormGroup = this.fb.group({
			otp: ['', Validators.required],
		})

		//Third Form
		this.thirdFormGroup = this.fb.group({
			employeeType: ['', [Validators.required]],

		})

		this.threePointZerotwo = this.fb.group({
			reside: ['', [Validators.required]]
		})

		this.thirdPointZeroFormGroup = this.fb.group({
			employeeSubType: ['', [Validators.required]],
			other: ['']
		})



		//Three.point.five
		this.thirdPointOneFormGroup = this.fb.group({
			states: ['', [Validators.required]]
		})

		//Fourth Form
		this.fourthFormGroup = this.fb.group({
			states: this.fb.array([]),
		})

		//Fifth Form
		this.fifthFormGroup = this.fb.group({
			otherlicense: ['', [Validators.required]],
			languages: ['', [Validators.required]],
			ei: false,
			ps: false,
			sa: false,
			ad: false,
			gr: false,

		})

		//sixth form grp
		this.sixthFormGroup = this.fb.group({
			time: ['', [Validators.required]]
		})
		
		//seventh 
		this.seventhFormGroup = this.fb.group({
			morning: false,
			afternoon: false,
			evening: false
		})

		//Eight
		this.eightFormGroup = this.fb.group({
			resume: ['']
		})

		//9 If for States linked to 4
		this.ninthFormGroup = this.fb.group({
		})

		//10 Address
		this.Form10Group = this.fb.group({
			haveLocation: ['', [Validators.required]],
			primaryLocation: [""],
			AdditionalLocation1: [""],
			AdditionalLocation2: [""]
		})

		//11 Plans
		this.Form11Group = this.fb.group({
			plan: ['']
		})

		//12 Network benifits
		this.Form12Group = this.fb.group({
			networkBenifits: ['']
		})

		this.Form13Group = this.fb.group({
			areas: ['']
		})

		this.Form14Group = this.fb.group({
			insurance: ['']
		})

		this.Form15Group = this.fb.group({
			rate: ['']
		})

		this.Form16Group = this.fb.group({
			ageGroups: ['']
		})

		this.Form17Group = this.fb.group({
			languages: ['']
		})

		this.Form18Group = this.fb.group({
			experience: ['']
		})

		this.Form19Group = this.fb.group({
			inPersonTreatment: ['']
		})

		this.Form20Group = this.fb.group({
			day: ['']
		})

		this.Form21Group = this.fb.group({
			picture: ['']
		})

		this.Form22Group = this.fb.group({
			education: [''],
			research: [''],
			links: ['']
		})

		var query_map = this.rl.snapshot.queryParamMap
		// console.log(query_map.get("email"), "Blahdfkuygfdskugdflhgfdlkjhgjfghj")
		this.firstFormGroup.patchValue({"fname" : query_map.get("fname")})
		this.firstFormGroup.patchValue({"lname" : query_map.get("lname")})
		this.firstFormGroup.patchValue({"phone" : query_map.get("mobile")})
		this.firstFormGroup.patchValue({"email" : query_map.get("email")})
		this.getActiveLicenseDetails()

	
	}


	checkAgeGroup() {
		var valid = false;
		if (this.fifthFormGroup.value.ei == false && this.fifthFormGroup.value.ps == false && this.fifthFormGroup.value.sa == false && this.fifthFormGroup.value.ad == false && this.fifthFormGroup.value.gr == false){
			return valid 
		}
		return true
	}

	checkDay(){
		var values = this.seventhFormGroup.value
		if (values.morning == false && values.afternoon == false && values.evening == false){
			return false;
		}
		return true
	}

	findState(state: any, find: string) {
		return state.value == find;
	}



	generateStates(page: number) {
	
		this.states().clear();
		for (let index = 0; index < this.thirdPointOneFormGroup.value.states.length; index++) {
			const element = this.thirdPointOneFormGroup.value.states[index];
			this.selectedStatesList.push({"state" : element, "licensenumber" : "", "expirydate" : ""})	
			var nameOfState: any = environment.statesInKeyShortCode.find(item => item.value == element)?.name
			this.states().push(this.statesRows(nameOfState, index))
		}
		this.page = page

	}


	deleteRecordOfState(i: number){
		this.states().removeAt(i)
	}
	
	clearStateTable(page: number){
		this.states().clear()
		this.page = page
	}

	prevPage(i?: number){
		if (i == undefined){
			this.page --;
		}
		else {
			this.page = i;
		}
	}

	onChange(event: any) {
        this.file = event.target.files[0];
    }
  
    onUpload(page: number) {
        var buffer = this.file?.arrayBuffer().then(
			(data) => {
				this.ResumeData = data; 
				this.page = page;
			}
		);
    }


	
	nextPage(i?: number){
		if (i == undefined){
			this.page ++;
		}
		else {
			this.page = i;
		}
	}

	navigateToStates(){
		if (this.thirdPointZeroFormGroup.value.employeeSubType == '1' || this.thirdPointZeroFormGroup.value.employeeSubType == '2') {
			if (this.thirdFormGroup.value.employeeType == '1') {
				this.page = 3.1
			}
			else {
				this.page = 3.1
			}
		}
		else { 
			if (this.thirdFormGroup.value.employeeType == '1') {
				this.page = 3.02
			}
			else {
				this.page = 9.01
			}
		}
	}

	submit(page: number, skipVaidation?: boolean, redirect: boolean = true) {
		this.saveTherapistDetails(page)
	}

	getAgeGroups(){
		var groups = []
		var values = this.fifthFormGroup.value
		if (values.ei == true){
			groups.push(1)
		}
		 if (values.ps == true) {
			groups.push(2)
		}
		 if (values.sa == true) {
			groups.push(3)
		}
		 if (values.ad == true) {
			groups.push(4)
		}
		 if (values.gr == true) {
			groups.push(5)
		}
		return groups
	}		

	getDays() {
		var days = []
		var values = this.seventhFormGroup.value
		if (values.morning == true)
			days.push(1)
		 if (values.afternoon == true)
			days.push(2)
		 if (values.evening == true)
			days.push(3)
		return days
	}		
	getEmployeeSubType(){
		var emp = this.thirdPointZeroFormGroup.value.employeeSubType
		if (emp == 1 || emp == 3 ||emp == 5 ||emp == 7)
			return 1
		else if(emp == 2 || emp == 4 || emp == 6)
			return 2
		else
			return 8
	}

	saveTherapistDetails(page: number){
		this.spinner.show()
		var licenseDetails = this.fourthFormGroup.getRawValue()
		licenseDetails = licenseDetails.states
		for (let index = 0; index < licenseDetails.length; index++) {
			licenseDetails[index]['licensestate'] = environment.statesInKeyShortCode.find(item => item.name == licenseDetails[index]['licensestate'])?.value
			if (licenseDetails[index]['expirydate'] == ""){
				delete licenseDetails[index]['expirydate']
			}
		}
		// console.log(this.thirdPointOneFormGroup.value.states)
		var data = {
			FirstName: this.firstFormGroup.value.fname,
			LastName: this.firstFormGroup.value.lname,
			EmailID: this.firstFormGroup.value.email,
			Mobilenumber: this.firstFormGroup.value.phone,
			EmployeeTypeId: this.thirdFormGroup.value.employeeType, //this.thirdPointZeroFormGroup.value.employeeSubType != 'other' ? this.thirdPointZeroFormGroup.value.employeeSubType : this.thirdPointZeroFormGroup.value.other,
			EmployeeSubTypeID: this.getEmployeeSubType(),//this.thirdFormGroup.value.employeeType,
			LicenseState: this.thirdPointOneFormGroup.value.states != "" ? this.thirdPointOneFormGroup.value.states.join(",") : "",
			OtherStatelicensure: this.fifthFormGroup.value.otherlicense,
			Languages: this.fifthFormGroup.value.languages == "" ? this.fifthFormGroup.value.languages : this.fifthFormGroup.value.languages.join(","),
			AgeGroup:  this.getAgeGroups().join(","),
			HourPerWeek: this.sixthFormGroup.value.time,
			HourOfDay: this.getDays().join(","),
			Isphysicallocation: this.Form10Group.value.haveLocation,
			Therapistprofessionallicense: licenseDetails,
			CompanyId: "1",
			TherapistEnquiryID: this.TherapistEnquiryID,
			ActiveLicense: this.thirdPointZeroFormGroup.value.employeeSubType,
			otherActiveLicensure: this.thirdPointZeroFormGroup.value.other,
			RequestSource: "2"
		}
		const formData = new FormData()
		this.file == undefined ? "" : formData.append("file", this.file, this.file.name);
		formData.append("therapistEnquiry", JSON.stringify(data))
		this.http.post(`${environment.server_url}api/Network/SaveNetworkTherapistEnquiry`, formData).subscribe(
			(res: any) => {
				// console.log(res); 
				this.spinner.hide(); 
				this.page = page
				if (page != 2) {
					if (res['MessageDetail'] == "Therapist details saved successfully"){
						this.content.showSucess("Your details have been saved successfully", "Success")
					}
					else {
						this.content.showError("There was some error while submitting your application.", "Error while submitting")
					}
				}
			},
			(error: any) => {
				this.content.showError("There was some error while submitting your application.", "Error while submitting")
				this.spinner.hide();
			}
			)}

	
  
}


export interface SelectedStatesList {
	state: string;
	licensenumber?: string;
	expirydate: string;
}

export class MyErrorStateMatcher implements ErrorStateMatcher {
	isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
	  const invalidCtrl = !!(control && control.invalid);
	  const invalidParent = !!(control && control.parent && control.parent.invalid);
  
	  return (invalidCtrl || invalidParent);
	}
}

interface EmployeeType {
	ActivelicenseId: number;
	LicenseName: string;
	Active: boolean;
	NeedLicense: boolean
}