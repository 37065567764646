// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  server_url: "https://testapi.slptele.com/",
  DocURL:"https://testdoc.slptele.com/Uploads/SLPTele/OnlineProfile/",
  statesList:  [
    ['AK', 'Alaska'],
    ['AL', 'Alabama'],
    ['AR', 'Arkansas'],
    ['AZ', 'Arizona'],
    ['CA', 'California'],
    ['CO', 'Colorado'],
    ['CT', 'Connecticut'],
    ['DC', 'District of Columbia'],
    ['DE', 'Delaware'],
    ['FL', 'Florida'],
    ['GA', 'Georgia'],
    ['HI', 'Hawaii'],
    ['IA', 'Iowa'],
    ['ID', 'Idaho'],
    ['IL', 'Illinois'],
    ['IN', 'Indiana'],
    ['KS', 'Kansas'],
    ['KY', 'Kentucky'],
    ['LA', 'Louisiana'],
    ['MA', 'Massachusetts'],
    ['MD', 'Maryland'],
    ['ME', 'Maine'],
    ['MI', 'Michigan'],
    ['MN', 'Minnesota'],
    ['MO', 'Missouri'],
    ['MS', 'Mississippi'],
    ['MT', 'Montana'],
    ['NC', 'North Carolina'],
    ['ND', 'North Dakota'],
    ['NE', 'Nebraska'],
    ['NH', 'New Hampshire'],
    ['NJ', 'New Jersey'],
    ['NM', 'New Mexico'],
    ['NV', 'Nevada'],
    ['NY', 'New York'],
    ['OH', 'Ohio'],
    ['OK', 'Oklahoma'],
    ['OR', 'Oregon'],
    ['PA', 'Pennsylvania'],
    ['RI', 'Rhode Island'],
    ['SC', 'South Carolina'],
    ['SD', 'South Dakota'],
    ['TN', 'Tennessee'],
    ['TX', 'Texas'],
    ['UT', 'Utah'],
    ['VA', 'Virginia'],
    ['VT', 'Vermont'],
    ['WA', 'Washington'],
    ['WI', 'Wisconsin'],
    ['WV', 'West Virginia'],
    ['WY', 'Wyoming'],
  ],
  "languages" : [
    ["En", "English"],
    ["Es", "Spanish"]
  ],
  "statesInKeyShortCode": [
    { value: 'AK', name: 'Alaska'},
    { value: 'TX', name: 'Texas'},
    { value: 'AL', name: 'Alabama'},
    { value: 'AR', name: 'Arkansas'},
    { value: 'AZ', name: 'Arizona'},
    { value: 'CA', name: 'California'},
    { value: 'CO', name: 'Colorado'},
    { value: 'CT', name: 'Connecticut'},
    { value: 'DC', name: 'DistrictofColumbia'},
    { value: 'DE', name: 'Delaware'},
    { value: 'FL', name: 'Florida'},
    { value: 'GA', name: 'Georgia'},
    { value: 'HI', name: 'Hawaii'},
    { value: 'IA', name: 'Iowa'},
    { value: 'ID', name: 'Idaho'},
    { value: 'IL', name: 'Illinois'},
    { value: 'IN', name: 'Indiana'},
    { value: 'KS', name: 'Kansas'},
    { value: 'KY', name: 'Kentucky'},
    { value: 'LA', name: 'Louisiana'},
    { value: 'MA', name: 'Massachusetts'},
    { value: 'MD', name: 'Maryland'},
    { value: 'ME', name: 'Maine'},
    { value: 'MI', name: 'Michigan'},
    { value: 'MN', name: 'Minnesota'},
    { value: 'MO', name: 'Missouri'},
    { value: 'MS', name: 'Mississippi'},
    { value: 'MT', name: 'Montana'},
    { value: 'NC', name: 'NorthCarolina'},
    { value: 'ND', name: 'NorthDakota'},
    { value: 'NE', name: 'Nebraska'},
    { value: 'NH', name: 'NewHampshire'},
    { value: 'NJ', name: 'NewJersey'},
    { value: 'NM', name: 'NewMexico'},
    { value: 'NV', name: 'Nevada'},
    { value: 'NY', name: 'NewYork'},
    { value: 'OH', name: 'Ohio'},
    { value: 'OK', name: 'Oklahoma'},
    { value: 'OR', name: 'Oregon'},
    { value: 'PA', name: 'Pennsylvania'},
    { value: 'RI', name: 'RhodeIsland'},
    { value: 'SC', name: 'SouthCarolina'},
    { value: 'SD', name: 'SouthDakota'},
    { value: 'TN', name: 'Tennessee'},
    { value: 'TX', name: 'Texas'},
    { value: 'UT', name: 'Utah'},
    { value: 'VA', name: 'Virginia'},
    { value: 'VT', name: 'Vermont'},
    { value: 'WA', name: 'Washington'},
    { value: 'WI', name: 'Wisconsin'},
    { value: 'WV', name: 'WestVirginia'},
    { value: 'WY', name: 'Wyoming'}
  ],
  avatarURL: "https://www.w3schools.com/howto/img_avatar2.png",
  website: "https://slptele.com/",
  forms: "http://localhost:4200/",

  supportedLanguages:  [
		["English", "1"],
		["Spanish", "2"],
		['Armenian', "3"],
		["Arabic", "4"],
		["Mandarin", "5"],
		['Telugu', "6"],
		["Korean", "7"],
		["Russian", "8"],
		["Tagalog", "9"],
		["Hindi", "10"],
		["Kannada", "11"],
		["Cantonese", "12"]
	]
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
