import { HttpClient } from '@angular/common/http';
import { Component, Injectable, OnInit, ViewChild } from '@angular/core';
import {  FormGroup, FormBuilder } from '@angular/forms';
import { Validators } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { OtpserviceService } from '../otpservice.service';
import { NgxSpinnerService } from "ngx-spinner";
import {MatAccordion} from '@angular/material/expansion';
import { ContentService, AreaOfNeeds, ListResultOfAreaOfNeeds } from '../content.service'
import { FlatTreeControl } from "@angular/cdk/tree";
import { ActivatedRoute } from '@angular/router';
import{WhiteSpaceValidator} from '../WhiteSpace.validator'
@Component({
  selector: 'app-new-client',
  templateUrl: './new-client.component.html',
  styleUrls: ['./new-client.component.scss'],

})
export class NewClientComponent implements OnInit {
  @ViewChild(MatAccordion) accordion!: MatAccordion;
  constructor(
    private fb: FormBuilder, 
    private http: HttpClient,
    private otpservice: OtpserviceService,
    private spinner: NgxSpinnerService,
    private content: ContentService,
    private rl: ActivatedRoute) {
  }
  environment = environment
  statesInKeyShortCode = environment.statesInKeyShortCode
  firstFormGroup!: FormGroup;
  secondFormGroup!: FormGroup;
  thirdFormGroup!: FormGroup;
  fourthFormGroup!: FormGroup;
  fifthFormGroup!: FormGroup;
  sixthFormGroup!: FormGroup;
  seventhFormGroup!: FormGroup;
  eightFormGroup!: FormGroup;
  searchTherapist!: string;
  filterState!: string;
  treatmentType!: string;
  mouse = false
  therapistsData: TherapistData[] = []
  autoComplete: TherapistData[] = []
  wrongOTP = "";
  page = 1;
  window = window
  otpverified = false;
  zipcodeFilter = "";
  speacitlityFilter = "";
  languageFilter = ""
  todayDate = new Date()
  supportedLanguages = environment.supportedLanguages
  file!: File; 
  ResumeData!: ArrayBuffer
  output!: number
  clientId !: number
  areaOfNeeds!: AreaOfNeeds
  dataSouce!: [ListResultOfAreaOfNeeds]
  statesZipData!: StateJSON[]



  showMissingFieldsError() {
    this.content.showError("Please fill the missing fields highlighted in Red", "Missing/Invalid Fields")
  }


  checkclient(valid: boolean) {
    if (valid){
    this.spinner.show()
    this.http.get(`${environment.server_url}api/Client/GetClientsByPhone/?searchString=${this.firstFormGroup.value.mobile}`).subscribe(
      (data: any) => {
        if (data['ListResult'].length == 0){
          this.sendOTP()
        }
        else {
          this.content.showSucess("Welcome back to SLP-tele", "Welcome back")
           this.page = 1.1
          //this.page = 7
          this.spinner.hide()
        }
      }
    )
  }
  else {
    this.showMissingFieldsError()
  }

  }

  setClientDetails() {
    this.http.get(`${environment.server_url}api/client/GetClientDetails/?clientID=${this.clientId}`).subscribe(
      (data: any) => { 
        // console.log(data)
        var list = data['Result']
        var three = this.thirdFormGroup
        if (list['DOB'] != null){
          three.patchValue({"dob" : list['DOB']})
          three.patchValue({"gender" : list['Sex'].toString()})
          three.patchValue({"other" : list['OtherGender']})
          three.patchValue({"languages" : list['LanguageID'] == null ? list['LanguageID'] : list['LanguageID'].toString()})
          three.patchValue({"zipcode" : list['ZipCode']})
          this.spinner.hide()
        }
        // this.page ++;
      }
    )
  }

  sendOTP() {
    this.spinner.show()
    if (this.otpverified){
      this.spinner.hide()
      this.page = 2
      return
    }
    this.otpservice.sendOTP(this.firstFormGroup.value.mobile, this.firstFormGroup.value.email).subscribe(
      (otp: any) => {
        // console.log(otp)
        this.content.showSucess("One Time Code sent successfully", "One Time Code sent!")
        this.spinner.hide();
        this.page = 2;
      },
      (error: any) => {
        this.content.showError("There was an some unexpected error. Please try after some time", "Please try after some time")
      }
    );

  }



  validateAuthorizationForm(): boolean{
    if (this.fifthFormGroup.value.haveAuth == "yes"){
      var six = this.sixthFormGroup.value
      if (six.insuranceName == "" || six.insuranceNumber == "" || six.authorizationNumber == '' || six.startDate == '' || six.endDate == ""){
        return false
      }
      else {
        return true
      }
    }
    else if (this.fifthFormGroup.value.haveAuth == ""){
      return false
    }
    else {
      var seven = this.seventhFormGroup.value
      if (seven.ap != '' || seven.rl != '' || seven.el != '' || seven.fy != '' || seven.vc != '' || seven.dy != '' || seven.cl != ''|| seven.oh != ''){
        return true
      }
      return false
    }
  } 

  validateInsuranceServices(): boolean{
    if (this.eightFormGroup.value.insurance == ''){
      return false
    }
    return true
  }

  getAreaOfNeedsFromAPI() {
    this.http.get<AreaOfNeeds>(`${environment.server_url}api/Network/GetSpecialties?Companyid=1`).subscribe(
      data => {
        data.ListResult.forEach(
          element => {
            element.checked = false
          }
        )
        this.areaOfNeeds = data
        // console.log(this.areaOfNeeds)
      }
    )
    }

  
    getStateWithZip(zip: string): string {
    if (zip == "0") {
      return ""
    }
    return this.statesZipData.find(x => x.zip == zip)!.state

    }

  ngOnInit(): void {



    this.http.get<StateJSON[]>("/assets/states.json").subscribe(
      data => {
        this.statesZipData = data
      }
    )

    this.getAreaOfNeedsFromAPI()
    this.firstFormGroup = this.fb.group({
      firstname: ['', [Validators.required, Validators.pattern(/[A-Za-z\s]/),WhiteSpaceValidator.cannotContainOnlySpace]],
      lastname: ['', [Validators.required, Validators.pattern(/[A-Za-z\s]/),WhiteSpaceValidator.cannotContainOnlySpace]],
      mobile: ['', [Validators.required, Validators.pattern(/^\d{10}$/)]],
      email: ['', [Validators.required, Validators.email]]
    })
    
    this.secondFormGroup = this.fb.group({
      otp: ['', [Validators.required]]
    })

    this.thirdFormGroup = this.fb.group({
      dob: ['', [Validators.required]],
      gender: ['4', [Validators.required]],
      other: [''],
      zipcode: ['', [Validators.required, Validators.pattern(/(^[0]$|^\d{5}$)/)]],
      languages: ['', [Validators.required]]
    })

    this.fourthFormGroup = this.fb.group({
      therapist: ['']
    })

    this.fifthFormGroup = this.fb.group({
      haveAuth: ["yes", [Validators.required]],
      type: ['1', Validators.required],
      time: ['1'],
      sun: [''],
      mon: [''],  
      tue: [''],
      wed: [''],
      thu: [''],
      fri: [''],
      sat: [''],
    })

    this.sixthFormGroup = this.fb.group({
      insuranceName: [''],
      insuranceNumber: [''],
      authorizationNumber: [''],
      startDate: [''],
      endDate: [''],
    })

    this.seventhFormGroup = this.fb.group({
      26: ['',],
      1: ['',],
      2: ['',],
      3: ['',],
      4: ['',],
      5: ['',],
      6: ['',],
      7: ['',],
      8: ['',],
      9: ['',],
      10: [''],
      11: [''],
      12: [''],
      13: [''],
      14: [''],
      15: [''],
      16: [''],
      17: [''],
      18: [''],
      19: [''],
      25: [''],
      20: [''],
      21: [''],
      22: [''],
      23: [''],
      24: [''],

      othervalue: ['']

    })

    this.eightFormGroup = this.fb.group({
      insurance: [''],
      insurancePlan: ['2'],
      insuranceName: [''],
      insuranceId: [''],
      prescription: [''],
      auth: [''],
      resume: ['']
      
    })

    // this.loadInitialTherapists()
    var query_map = this.rl.snapshot.queryParamMap
    // console.log(query_map.get("email"))
    this.firstFormGroup.patchValue({"firstname" : query_map.get("fname")})
    this.firstFormGroup.patchValue({"lastname" : query_map.get("lname")})
    this.firstFormGroup.patchValue({"mobile" : query_map.get("mobile")})
    this.firstFormGroup.patchValue({"email" : query_map.get("email")})
  }

 


  clearSearch() {
    this.searchTherapist = "";
    this.filterState = "";
    this.languageFilter = "";
    this.speacitlityFilter = ""
    this.therapistsData.splice(0, this.therapistsData.length)
  }


  loadInitialTherapists(display = true) {
    display == true ? this.spinner.show() : this.spinner.hide()
    var initialData = {
      "TreatmentType": "",
      "ProviderName":this.searchTherapist,
      "Zip":this.zipcodeFilter,
      "State":this.filterState,
      "LanguageId" : this.languageFilter,
      "Speciality" : this.speacitlityFilter
    }
    this.http.post(`${environment.server_url}api/Network/SearchProvider`, initialData).subscribe(
      ((data: any) => {
        window.localStorage.clear()
        this.therapistsData.splice(0, this.therapistsData.length)
        this.autoComplete.splice(0, this.autoComplete.length)

        data['ListResult'].forEach((element: TherapistData) => {
          if (display){
          this.therapistsData.push(new TherapistData(element.userid, element.ProviderName, element.specialities, 
            element.PhotoFilePath != "" ? element.PhotoFilePath : environment.avatarURL , 
            element.WhyMe, element.state, element.areaofexpertise, element.LicenseName))
          }
          else {
            this.autoComplete.push(new TherapistData(element.userid, element.ProviderName, element.specialities, 
              element.PhotoFilePath != "" ? element.PhotoFilePath : environment.avatarURL , 
              element.WhyMe, element.state, element.areaofexpertise, element.LicenseName))
          }
        });
        window.localStorage.setItem("therapistData", JSON.stringify(this.therapistsData))
        this.spinner.hide()
      }),
      error => {
        this.therapistsData.splice(0, this.therapistsData.length)
        // console.log("Cane mere")
        this.spinner.hide()
      }
    )
  }

	onChange(event: any) {
    this.file = event.target.files[0];

}

  onUpload(page: number) {
      var buffer = this.file?.arrayBuffer().then(
    (data) => {
      this.ResumeData = data; 
      this.page = page;
    } 
  );
  }

  previous(i?: number){
    if (i == undefined){
      this.page --
    }
    else {
      this.page = i
    }
  }


  next(valid: boolean, i?: number, title: string = "", message: string = ""){
    // console.log(valid)
    if (!valid) {
      if (title != "" && message != "") {
        this.content.showError(message, title)
        return 
      }
      this.showMissingFieldsError()
      return 
    }

    if (i == undefined){
      this.page ++
    }
    else {
      this.page = i
    }
  }


  checkExistingAuth(valid: boolean)  {

    if (!valid) {
      this.showMissingFieldsError()
      return 
    }

    this.spinner.show()
    if (this.otpverified) {
      this.page ++;
      this.spinner.hide()
      return 
    }
    var otp = this.secondFormGroup.value.otp
    var mobile = this.firstFormGroup.value.mobile

    this.otpservice.validateOTP(mobile, otp).subscribe(
      (value: any) => {
        // console.log(value, value['Result'])
        if (value['Result'])
          {
            this.otpverified = true
            this.content.showSucess("One Time Code Verified successfully", "Verification Successfull")
            //  Save the client details
            var body = {
              Companyid: "1",
              LastName: this.firstFormGroup.value.lastname,
              FirstName: this.firstFormGroup.value.firstname,
              MobileNumber: mobile,
              ContactInfoDTO: {
                Mobile: mobile,
                Email: this.firstFormGroup.value.email,
              },
              CreateDate: this.todayDate,
              Active: "true",
              CreatedBy: "1",
              IsSendEmail: "true",
              IsWalkinClient: "true",
              RequestSource: "2",
              ClientSourceId:"1"
            }
            this.http.post(`${environment.server_url}api/Client/SaveClientDetails`, body).subscribe(
              (data: any) => {
                this.clientId = data['ID']
                this.setClientDetails()
                this.spinner.hide()
                this.page ++;
              },
              (error) => {
                this.clientId = error['error']['ID']
                this.setClientDetails()
                this.spinner.hide()
                this.page++
              }
              
            )
        }
      else {
        this.wrongOTP = "Invalid One Time Code";
        this.content.showError("You've entered an Invalid One Time Code. Please try again", "Invalid One Time Code")
        this.spinner.hide()
        // this.page++
      }
    }
    )
  }

  // getParentAreaOfNeeds(sub: number) {
  //   this.areaOfNeeds.ListResult.find(x => x.)
  // }

  setAreaOfNeeds(data: string) {
    var data_split = data.split(",")
    if (data_split.indexOf("1") != -1) {
      
      this.seventhFormGroup.patchValue({"1" : true})
    } 
    if (data_split.indexOf("2") != -1) {
      this.seventhFormGroup.patchValue({"2" : true})
    } 
    if (data_split.indexOf("3") != -1) {
      this.seventhFormGroup.patchValue({"3" : true})
    } 
    if (data_split.indexOf("4") != -1) {
      this.seventhFormGroup.patchValue({"4" : true})
    } 
    if (data_split.indexOf("5") != -1) {
      this.seventhFormGroup.patchValue({"5" : true})
    } 
    if (data_split.indexOf("6") != -1) {
      this.seventhFormGroup.patchValue({"6" : true})
    } 
    if (data_split.indexOf("7") != -1) {
      this.seventhFormGroup.patchValue({"7" : true})
    } 
    if (data_split.indexOf("8") != -1) {
      this.seventhFormGroup.patchValue({"8" : true})
    } 
    if (data_split.indexOf("9") != -1) {
      this.seventhFormGroup.patchValue({"9" : true})
    } 
    if (data_split.indexOf("10") != -1) {
      this.seventhFormGroup.patchValue({"10" : true})
    } 
    if (data_split.indexOf("11") != -1) {
      this.seventhFormGroup.patchValue({"11" : true})
    } 
    if (data_split.indexOf("12") != -1) {
      this.seventhFormGroup.patchValue({"12" : true})
    } 
    if (data_split.indexOf("13") != -1) {
      this.seventhFormGroup.patchValue({"13" : true})
    } 
    if (data_split.indexOf("14") != -1) {
      this.seventhFormGroup.patchValue({"14" : true})
    } 
    if (data_split.indexOf("15") != -1) {
      this.seventhFormGroup.patchValue({"15" : true})
    } 
    if (data_split.indexOf("16") != -1) {
      this.seventhFormGroup.patchValue({"16" : true})
    } 
    if (data_split.indexOf("17") != -1) {
      this.seventhFormGroup.patchValue({"17" : true})
    } 
    if (data_split.indexOf("18") != -1) {
      this.seventhFormGroup.patchValue({"18" : true})
    } 
    if (data_split.indexOf("19") != -1) {
      this.seventhFormGroup.patchValue({"19" : true})
    } 
    if (data_split.indexOf("20") != -1) {
      this.seventhFormGroup.patchValue({"20" : true})
    } 
    if (data_split.indexOf("21") != -1) {
      this.seventhFormGroup.patchValue({"21" : true})
    } 
    if (data_split.indexOf("22") != -1) {
      this.seventhFormGroup.patchValue({"22" : true})
    } 
    if (data_split.indexOf("23") != -1) {
      this.seventhFormGroup.patchValue({"23" : true})
    } 
    if (data_split.indexOf("24") != -1) {
      this.seventhFormGroup.patchValue({"24" : true})
    } 
    if (data_split.indexOf("25") != -1) {
      this.seventhFormGroup.patchValue({"25" : true})
    } 
  
    if (data_split.indexOf("26") != -1) {
      this.seventhFormGroup.patchValue({"26" : true})
    } 
  }

  setPreferredDay(data: string) {
    var data_split = data.split(",")
    if (data_split.indexOf("1") != -1){
      this.fifthFormGroup.patchValue({"sun" : true})
    }
    if (data_split.indexOf("2") != -1){
      this.fifthFormGroup.patchValue({"mon" : true})
    }
    if (data_split.indexOf("3") != -1){
      this.fifthFormGroup.patchValue({"tue" : true})
    }
    if (data_split.indexOf("4") != -1){
      this.fifthFormGroup.patchValue({"wed" : true})
    }
    if (data_split.indexOf("5") != -1){
      this.fifthFormGroup.patchValue({"thu" : true})
    }
    if (data_split.indexOf("6") != -1){
      this.fifthFormGroup.patchValue({"fri" : true})
    }
    if (data_split.indexOf("7") != -1){
      this.fifthFormGroup.patchValue({"sat" : true})
    }

  }

  updateclient(page: number, valid: boolean) {

    if (!valid) {
      this.showMissingFieldsError()
      return 
    }

    this.spinner.show();
    var third = this.thirdFormGroup.value
    var data = {
      clientid: this.clientId,
      dob: third.dob,
      sex: third.gender,
      otherGender: third.other,
      languageID: third.languages,
      zipCode: third.zipcode,
      pronoun: "1"
    }

    this.http.post(`${environment.server_url}api/Client/UpdateClient`, data).subscribe(
      (res: any) => {
        // console.log(res);
        this.spinner.hide()
        this.page = page
      }
    )
  this.http.get(`${environment.server_url}api/Client/GetClientWalkingDetails?clientID=${this.clientId}`).subscribe(
    (data: any) => {
      data = data['Result']
      // console.log(data)
      this.setAreaOfNeeds(data['Areaofneeds'])
      this.seventhFormGroup.patchValue({"othervalue" : data['OtherAreaOfNeeds']})
      this.fifthFormGroup.patchValue({"haveAuth" : data['PayingService'] == '1' ? 'yes' : "no"})
      this.eightFormGroup.patchValue({"insurance" : data['InsurancePlanId'].toString()})
      this.eightFormGroup.patchValue({"insurancePlan" : data['PlanTypeId'] != null ? data['PlanTypeId'].toString() : "" })
      this.eightFormGroup.patchValue({"insuranceName" : data['InsuranceName']}),
      this.eightFormGroup.patchValue({"insuranceId" : data['InsuranceNumber']})
      this.eightFormGroup.patchValue({"auth" : data['IsAuth'] == 'true' ? "yes" : "no"})
      this.eightFormGroup.patchValue({"isPrescription" : data['IsPrescription'] == 'true' ? "yes" : "no"})
      this.eightFormGroup.patchValue({"insuranceId":data['MedicaidNumber']})
      this.eightFormGroup.patchValue({"insuranceName":data['ManagedCarePlanName']})
      this.eightFormGroup.patchValue({"insuranceId":data['ManagedCarePlanNumber']})
      this.eightFormGroup.patchValue({"insuranceId":data['MedicareNumber']})
      this.fifthFormGroup.patchValue({"type":data['preferSession']})
      this.fifthFormGroup.patchValue({"time" : data['preferSessionTimeSession']})
      this.eightFormGroup.patchValue({"insurancePlan" : data['YourPlanTypeid'] != null ? data['YourPlanTypeid'].toString() : ""})
      this.setPreferredDay(data['preferDayTreatment'])
      
    }
  )

  }

  validateSevenPointZeroTwo() {
    // var eight = this.eightFormGroup.value
    // if ((eight.insurancePlan == '2' || eight.insurancePlan == '3') && (eight.insurance == '1' || eight.insurance == '5')) {
    //   if (eight.insuranceName == '' || eight.insuranceId == '' || eight.prescription == '') {
    //     // console.log(1)
    //     return false;
    //   }
    //   else {
    //     // console.log(2)

    //     return true
    //   }
    // }
    // if ((eight.insurancePlan == '1' || eight.insurancePlan == '4') && (eight.insurance == '1' || eight.insurance == '5') ) {
    //   if (eight.insuranceName == "" || eight.insuranceId == "" || eight.auth == ""){
    //     // console.log(3)

    //     return false
    //   }
    //   else {
    //     // console.log(4)

    //     return true
    //   }
    // }
    // if (eight.insurancePlan== '1' && eight.insurance == '2') {
    //   if (eight.insuranceId == "" || eight.auth == ""){
    //     // console.log(5)

    //     return false
    //   }
    //   else {
    //     return true
    //   }
    // }
    // if (eight.insurancePlan == '2' && eight.insurance == '2') {
    //   if (eight.insuranceName == '' || eight.insuranceId == "" || eight.auth == ""){
    //     // console.log(6)
    //     return false
    //   }
    //   else {
    //     // console.log(7)
    //     return true
    //   }
    // }
    // // console.log("NAA")
    // return false
    return true
  }


  validAreaOfNeeds(): boolean {
    var seven = this.seventhFormGroup.value
    for (var i = 0; i <= 26; i++){
      if (seven[i] == true){
        return true
      }
    }
    if (seven.oh != "" && seven.othervalue.length > 0){
      return true
    }
    return false
  }


  validateAddDetails(): boolean{
    var eight = this.eightFormGroup.value
    if (eight.insurance == '1' || eight.insurance == '5') {
      if (eight.insurancePlan == "" ) {
        return false
      }
      else {
        return true
      }
    }
    if (eight.insurance == '3') {
      if (eight.insuranceId == "" || eight.prescription == "") {
        return false
      }
      else {
        return true
      }
    }
    if (eight.insurance == '2') {
      if (eight.insurancePlan == "") {
        return false
      }
      else{ 
        return true
      }
    }
    if (eight.insurance == '4') {
      if (eight.insuranceName == "" || eight.insuranceId == "" || eight.prescription == "") {
        return false
      }
      else {
        return true
      }
    }
    return false
  }

  validateHalfFive(): boolean {
    var five = this.fifthFormGroup.value
    if (five.type != "" && five.time != "" && (five.sun == true || five.mon == true || five.tue == true || five.wed == true || five.thu == true || five.fri == true || five.sat == true)) {
      return true
    }
    return false
  }

  getAreaOfNeeds() {
    var areas = ""
    var seven = this.seventhFormGroup.value
    for (let i: any = 0; i <= 25; i++){
      if (seven[i] == true){
        areas += i.toString() + ","
      }
    }
    return areas
  }

  getPreferedDays() {
    var days = ""
    var five = this.fifthFormGroup.value
    if(five.sun) {
      days += "1,"
    }
    if(five.mon) {
      days += "2,"
    }
    if(five.tue) {
      days += "3,"
    }
    if(five.wed) {
      days += "4,"
    }
    if(five.thu) {
      days += "5,"
    }
    if(five.fri) {
      days += "6,"
    }
    if(five.sat) {
      days += "7,"
    }
    return days
  }

  submit(vadlid: boolean, page?: number) {
    if (!vadlid) {
      this.showMissingFieldsError()
      return 
    } 
    this.spinner.show()
    var seven = this.seventhFormGroup.value
    var eight = this.eightFormGroup.value
    var five = this.fifthFormGroup.value
    var data = {
      clientId: this.clientId.toString(),
      areaofneeds: this.getAreaOfNeeds(),
      otherAreaOfNeeds: seven.othervalue,
      payingService: five.haveAuth == 'yes'? "1": "2",
      insurancePlanId: eight.insurance,
      planTypeId: (eight.insurance == '1' || eight.insurance == '5') ?  "" : eight.insurancePlan,
      YourPlanTypeid: (eight.insurance == '1' || eight.insurance == '5') ? eight.insurancePlan : "",
      insuranceName: eight.insuranceName,
      insuranceNumber: eight.insuranceId,
      isAuth: eight.auth == 'yes' ? "true" : "false",
      authfileName: eight.auth == 'yes' ? this.file != undefined ? this.file.name: "" : "",
      isPrescription: eight.prescription == 'yes' ? "true" : "false",
      prescriptionfileName: eight.prescription == 'yes' ? this.file != undefined ? this.file.name: "" : "",
      medicaidNumber: eight.insuranceId,
      managedCarePlanName: eight.insuranceName,
      managedCarePlanNumber: eight.insuranceId,
      medicareNumber: eight.insuranceId,
      createdate: this.todayDate,
      createdby: "1",
      preferSession: five.type,
      preferSessionTimeSession: five.time,
      preferDayTreatment: this.getPreferedDays(),
      
      
    }

    const formData = new FormData()
		this.file == undefined ? "" : formData.append(this.file.name, this.file, this.file.name);
		formData.append("walkinClientDetails", JSON.stringify(data))
    this.http.post(`${environment.server_url}api/Client/saveWalkinClientDetail`, formData).subscribe(
      (res: any) =>  {
        if (res['StatusCode'] == 200) {
          this.spinner.hide()
          this.content.showSucess("Your details have been submitted successfully", "Success")
        }
        else {
          this.spinner.hide()
          this.content.showError("There was some error while submitting your application.", "Error while submitting")
        }
      },
      (error: any) => {
        this.spinner.hide()
        this.content.showError("There was some error while submitting your application.", "Error while submitting")
      }
    )
    if (eight.auth == 'yes' || eight.prescription == 'yes') {
      this.page = 7;
      this.output = 1
    }
    else {
      this.page = 7;
      this.output = 2;
    }
    this.page = page || 7;

  }

  treeControl = new FlatTreeControl<any>(
    (node) => node.level,
    (node) => node.expandable
  );

  checkChecked(sub?: number) {
    var parent = this.areaOfNeeds.ListResult.find(x => x.SpecialtiesId == sub)
    var success = false
    var count = 0
    var true_c = 0
    parent!.SubSpecialties.forEach(
      element => {
        count += 1
        if (this.seventhFormGroup.value[element.SubSpecialtiesId] == true){
          success = true
          true_c += 1
        }
      }
    )
    if (true_c == 0) {
      parent!.checked = false
      parent!.intermedite = false
      return 
    }
    if (!success) {
      parent!.checked = false
      return 
    }
    
    if (success && (count != true_c)){
      parent!.intermedite = false
      parent!.checked = true
    }
    else {
      parent!.intermedite = false
      parent!.checked = true
    }
    success = false
  }


  
}


export class TherapistData{
  userid!: number;
  ProviderName!: string;
  specialities!: string;
  PhotoFilePath!: any;
  WhyMe!: string;
  state!: string;
  areaofexpertise!: string;
  hover!: boolean;
  selected!: boolean;
  LicenseName: string

  constructor(
    userid: number,
    ProviderName: string,
    specialities: string,
    PhotoFilePath: any,
    WhyMe: string,
    state: string,
    areaofexpertise: string,
    LicenseName: string,
    hover: boolean = false,
    selected: boolean = false,
    ) {
      this.userid = userid
      this.ProviderName = ProviderName;
      this.specialities = specialities;
      this.PhotoFilePath = PhotoFilePath;
      this.WhyMe = WhyMe;
      this.state = state;
      this.areaofexpertise = areaofexpertise;
      this.hover = hover;
      this.selected = selected;
      this.LicenseName = LicenseName
    } 

}

export interface StateJSON {
  state: string
  zip: string
}