<div class="spinn">
    <ngx-spinner type="ball-scale-multiple"></ngx-spinner>
 </div>
 <div class="container">
    <div class="leftcontainer">
       <div class="form" *ngIf="page == 1">
          <form [formGroup]="firstFormGroup">
             <div class="col-md-4 first_form">
                <h1 class="head_h">Let's start with the basics</h1>
                <div class="row name">
                   <mat-form-field appearance="outline">
                      <mat-label for="fname">First Name</mat-label>
                      <input matInput type="text" id="fname" name="firstname" formControlName="firstname">
                   </mat-form-field>
                   <mat-form-field appearance="outline">
                      <mat-label for="lname">Last Name</mat-label>
                      <input matInput type="text" id="lname" name="lastname" formControlName="lastname">
                   </mat-form-field>
                </div>
                <div class="row">
                   <mat-form-field appearance="outline">
                      <mat-label for="phone">Your Phone</mat-label>
                      <input matInput matInput matInput type="text" id="phone" name="mobile"
                         formControlName="mobile" maxlength=10>
                   </mat-form-field>
                </div>
                <div class="row">
                   <mat-form-field appearance="outline">
                      <mat-label for="email">Your Email</mat-label>
                      <input matInput matInput matInput type="email" name="email" id="email"
                         formControlName="email">
                   </mat-form-field>
                </div>
                <div class="centerbutton">
                   <button class="btn btn-success" type="submit"   (click)="checkclient(firstFormGroup.valid)" >Next</button>
                   <!-- [disabled]=""   -->
                </div>
             </div>
             <div class="col-md-8 htw" style="padding-left: 10%;">
                <h1 class="hw_work">How this Works?</h1>
                <div class="row fst_dv">
                   <div class="col-sm-3 fst_dv1">
                      <img src="assets/sign-in1.png" class="stp_img">
                   </div>
                   <div class="col-sm-9">
                      <p class="stp_dtail">Fill this form and find the most appropriate therapist. We can find one
                         for you too!
                      </p>
                   </div>
                </div>
                <div class="row sec_dv">
                   <div class="col-sm-3 sec_dv1 ">
                      <img src="assets/calling.png" class="stp_img">
                   </div>
                   <div class="col-sm-9">
                      <p class="stp_dtail">Schedule a call with our scheduling team or the therapist directly.</p>
                   </div>
                </div>
                <div class="row trd_dv">
                   <div class="col-sm-3 trd_dv1">
                      <img src="assets/account1.png" class="stp_img">
                   </div>
                   <div class="col-sm-9">
                      <p class="stp_dtail">Get your financial information verified</p>
                   </div>
                </div>
                <div class="row frt_dv" style="margin-top: 30px;">
                   <div class="col-sm-3 frt_dv1">
                      <img src="assets/customer-service.png" class="stp_img">
                   </div>
                   <div class="col-sm-9">
                      <p class="stp_dtail">Start therapy session with the therapist</p>
                   </div>
                </div>
             </div>
          </form>
       </div>

       <div *ngIf="page == 1.1" class="col-md-6 haveacc">
         <h1>Seems like you have already contacted us in the past. Please continue if you want to update your information.</h1>
         <div class="centerbutton">
            <button class="btn btn-light" (click)="next(true, 1)" >No</button>
            <button class="btn btn-success" type="submit" (click)="sendOTP()">Yes</button>
         </div>
       </div>

       <div *ngIf="page == 2">
          <div class="col-md-6 sec_form">
             <form [formGroup]="secondFormGroup">
                <h1 class="head_h">
                   Please enter One Time Code sent to your Phone and Email
                </h1>
                <div class="col-12">
                   <div class="row otp_pass">
                      <mat-form-field appearance="outline">
                         <mat-label for="otp">Please enter One Time Code sent to your Phone and Email</mat-label>
                         <input matInput type="password" id="otp" name="otp" formControlName="otp" maxlength=6 minlength=6>
                      </mat-form-field>
                      <div class="row alert alert-warning" style="width: 80%; margin-left: 3%;" *ngIf="wrongOTP != ''">
                        <p class="alert-body" >
                           {{ wrongOTP }}
                        </p>
                     </div>
                   </div>
                  
                   <div class="centerbutton otpbtns">
                      <button class="btn btn-light" type="button" (click)="previous()">Previous</button>
                      <button class="btn btn-success sec_next" type="submit" 
                         (click)="checkExistingAuth(secondFormGroup.valid)" >Next</button>
                      <!-- <button class="btn btn-warn" type="button" (click)="sendOTP()" *ngIf="wrongOTP != ''">Resend One Time Code</button> -->
                   </div>
                </div>
             </form>
          </div>
          <div class="col-md-6 htw">
             <img src="assets/client/OTP2.png" class="first_t2">
          </div>
       </div>
       <div *ngIf="page == 3" class="form trd">
          <!-- 3 -->
          <div class="col-md-6 third_form">
             <form [formGroup]="thirdFormGroup">
                <h1 class="head_h" style="text-align: left;">I was born on</h1>
                <div class="row">
                   <div>
                      <mat-form-field class="dob_p" appearance="outline">
                         <mat-label>Date Of Birth</mat-label>
                         <input matInput [max]="todayDate" [matDatepicker]="picker" formControlName="dob">
                         <mat-hint>MM/DD/YYYY</mat-hint>
                         <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                         <mat-datepicker #picker></mat-datepicker>
                      </mat-form-field>
                   </div>
                </div>
                <br>
                <div class="radiodiv">
                   <h1 class="head_h" style="text-align: left;">Gender</h1>
                   <div class="form-check form-check-inline">
                      <input class="form-check-input" type="radio" id="male" name="gender" value="1" 
                         formControlName="gender">
                      <label class="form-check-label" for="male">Male</label>
                   </div>
                   <div class="form-check form-check-inline">
                      <input class="form-check-input" type="radio" id="female" name="gender" value="2" 
                         formControlName="gender">
                      <label class="form-check-label" for="female">Female</label>
                   </div>
                   <div class="form-check form-check-inline">
                      <input class="form-check-input" type="radio" id="transgender" name="gender" value="3"
                         formControlName="gender">
                      <label class="form-check-label" for="transgender">Transgender</label>
                   </div>
                   <div class="form-check form-check-inline">
                      <input class="form-check-input" type="radio" id="pnts" name="gender" value="4"
                         formControlName="gender">
                      <label class="form-check-label" for="pnts">Prefer not to say</label>
                   </div>
                   <div class="form-check">
                      <input class="form-check-input" type="radio" id="other" name="gender" value="5"
                         formControlName="gender">
                      <label class="form-check-label" for="other">Other</label>
                      <div class="form-check form-check-inline"  *ngIf="thirdFormGroup.value.gender == '5'">
                        <mat-form-field appearance="outline" style="width: auto;">
                           <mat-label>Gender</mat-label>
                           <input matInput type="text" formControlName="other">
                        </mat-form-field>
                     </div>
                   </div>
                   
                </div>
                <br>
                <!-- <div class="radiodiv">
                  <h1 class="head_h" style="text-align: left;">Pronoun</h1>
                  <div class="form-check form-check-inline">
                     <input class="form-check-input" type="radio" id="he" name="pronoun" value="1"
                        formControlName="pronoun">
                     <label class="form-check-label" for="he">He</label>
                  </div>
                  <div class="form-check form-check-inline">
                     <input class="form-check-input" type="radio" id="she" name="pronoun" value="2"
                        formControlName="pronoun">
                     <label class="form-check-label" for="she">She</label>
                  </div>
               </div> -->
               <br>

                <h1 class="head_h">Which language you want the treatment in?</h1>
                <mat-form-field style="width: 260px;" appearance="outline">
                   <mat-label>Which language you want the treatment in?</mat-label>
                   <mat-select formControlName="languages">
                      <mat-option *ngFor="let lang of supportedLanguages" [value]="lang[1]">{{ lang[0] }}
                      </mat-option>
                   </mat-select>
                </mat-form-field>
                <div>
                   <h1 class="head_h" style="text-align: left;">Zipcode</h1>
                   <mat-form-field appearance="outline">
                      <mat-label>Zipcode (Enter 0 for non US residents)</mat-label>
                      <input type="text" matInput formControlName="zipcode" maxlength=5>
                   </mat-form-field>
                </div>
                <div class="centerbutton">
                   <div>
                      <button class="btn btn-light" type="button" (click)="previous()">Previous</button>
                      <button class="btn btn-success ml-5" type="submit" (click)="updateclient(4, thirdFormGroup.valid)" >Next</button>
                   </div>
                   <!--   -->
                </div>
             </form>
          </div>
          *
          <div class="col-md-6 htw"><img src="assets/client/DOB.png" class="image_hlf"></div>
       </div>
       <div *ngIf="page == 4" class="col-md-12 relavent">
          <!-- 4 -->
          <form [formGroup]="seventhFormGroup" class="form_fstp">
             <h1 class="man_ttl1"> Select the most relevant area of need </h1>

            <div>
               <mat-accordion multi  *ngFor="let result of areaOfNeeds.ListResult" >
                  <mat-expansion-panel >
                     <mat-expansion-panel-header>
                        <mat-panel-title>
                           <mat-checkbox [disableRipple]="true" checked="{{ result.checked }}"   (change)="checkChecked(result.SpecialtiesId)" (click)="$event.preventDefault()" >
                              {{ result.SpecialtiesName }}
                           </mat-checkbox>
                        </mat-panel-title>
                     </mat-expansion-panel-header>
                     <div class="col-md-12">
                        <div class="form-check form-check-inline" *ngFor="let each of result.SubSpecialties">
                           <input class = "form-check-input" formControlName = "{{ each.SubSpecialtiesId }}" type = "checkbox" (change)="checkChecked(result.SpecialtiesId)" value = "{{ each.SubSpecialtiesId }}" id = "{{ each.SubSpecialtiesId }}">
                           <label class = "form-check-label" for ="{{ each.SubSpecialtiesId }}">{{ each.SubSpecialtiesName }}</label>
                        </div>
                     </div>
                  </mat-expansion-panel>
               </mat-accordion>
            </div>

             <div class="row">
                <div class="centerbutton col-md-6">
                   <button class="btn btn-light" type="button" (click)="previous()">Previous</button>
                   <button class="btn btn-success" type="submit" (click)="next(true, 4.01, 'Select atleast One', 'Please select atleast one Area Of Need')">Next</button>
                   <!-- -->
                </div>
             </div>
          </form>
       </div>
       <div *ngIf="page == 4.01" class="form">
          <div class="col-md-12">
             <form [formGroup]="fifthFormGroup">
                <div class="margin">
                   <h1> How would you prefer your session? </h1>
                   <div class="row">
                   <div class="col-md-6">
                      <div class="form-check">
                         <input class="form-check-input" checked formControlName="type" value="1" type="radio"
                            name="type" id="1">
                         <label class="form-check-label" for="1">As soon as possible with most qualified
                         therapist</label>
                      </div>
                   </div>
                   <div class="col-md-6">
                      <div class="form-check">
                         <input class="form-check-input" formControlName="type" value="2" type="radio"
                            name="type" id="2">
                         <label class="form-check-label" for="2">Virtual Online session only</label>
                      </div>
                   </div>
                   <div class="col-md-6">
                      <div class="form-check">
                         <input class="form-check-input" formControlName="type" value="3" type="radio"
                            name="type" id="3">
                         <label class="form-check-label" for="3"> In Person only</label>
                      </div>
                   </div>
                   <div class="col-md-6">
                      <div class="form-check">
                         <input class="form-check-input" formControlName="type" value="4" type="radio"
                            name="type" id="4">
                         <label class="form-check-label" for="4"> A mix of virtual and in person</label>
                      </div>
                   </div>
                </div>
                </div>
              
                <div class="margin">
                   <h1>What is your preferred time for your session?</h1>
                   <div class="row">
                   <div class="col-md-6">
                      <div class="form-check form-check-inline">
                         <input class="form-check-input" type="radio" id="asap" formControlName="time"
                            class="form-check-input" name="time" value="1">
                         <label for="asap" class="form-check-label">As soon as possible any time of the
                         day</label>
                      </div>
                   </div>
                   <div class="col-md-6">
                      <div class="form-check ">
                         <input class="form-check-input" type="radio" id="mrng" formControlName="time"
                            class="form-check-input" name="time" value="2">
                         <label for="mrng" class="form-check-label">Morning</label>
                      </div>
                   </div>
                   <div class="col-md-6">
                      <div class="form-check ">
                         <input class="form-check-input" type="radio" formControlName="time" id="noon"
                            class="form-check-input" name="time" value="3">
                         <label for="noon" class="form-check-label">Afternoon</label>
                      </div>
                   </div>
                   <div class="col-md-6">
                      <div class="form-check ">
                         <input class="form-check-input" type="radio" id="eve" formControlName="time"
                            class="form-check-input" name="time" value="4">
                         <label for="eve" class="form-check-label">Evening</label>
                      </div>
                   </div>
                </div>
                </div>
            
                <div class="margin">
                   <div  class="form_fstp">
                      <h1>What are your preferred days of treatment?</h1>
                      <div class="row">
                      <div class="col-md-6">
                         <div class="form-check form-check-inline">
                            <input type="checkbox" id="sun" formControlName="sun" class="form-check-input"
                               name="sun" value="1">
                            <label for="sun" class="form-check-label">Sunday</label>
                         </div>
                      </div>
                      <div class="col-md-6">
                         <div class="form-check form-check-inline">
                            <input type="checkbox" formControlName="mon" id="mon" class="form-check-input"
                               name="mon" value="2">
                            <label for="mon" class="form-check-label">Monday</label>
                         </div>
                      </div>
                      <div class="col-md-6">
                         <div class="form-check form-check-inline">
                            <input type="checkbox" id="tue" formControlName="tue" class="form-check-input"
                               name="tue" value="3">
                            <label for="tue" class="form-check-label">Tuesday</label>
                         </div>
                      </div>
                      <div class="col-md-6">
                         <div class="form-check form-check-inline">
                            <input type="checkbox" id="wed" formControlName="wed" class="form-check-input"
                               name="wed" value="4">
                            <label for="wed" class="form-check-label">Wednesday</label>
                         </div>
                      </div>
                      <div class="col-md-6">
                         <div class="form-check form-check-inline">
                            <input type="checkbox" formControlName="thu" id="thu" class="form-check-input"
                               name="thu" value="5">
                            <label for="thu" class="form-check-label">Thursday</label>
                         </div>
                      </div>
                      <div class="col-md-6">
                         <div class="form-check form-check-inline">
                            <input type="checkbox" id="fri" formControlName="fri" class="form-check-input"
                               name="fri" value="6">
                            <label for="fri" class="form-check-label">Friday</label>
                         </div>
                      </div>
                      <div class="col-md-6">
                         <div class="form-check form-check-inline">
                            <input type="checkbox" id="sat" formControlName="sat" class="form-check-input"
                               name="sat" value="7">
                            <label for="sat" class="form-check-label">Saturday</label>
                         </div>
                      </div>
                   </div>
                </div>
            </div>
                <div class="centerbutton">
                   <button class="btn btn-light" type="button" (click)="previous(4)">Previous</button>
                   <button class="btn btn-success" type="submit" (click)="next(true, 4.1)"  >Next</button>
                   <!--   -->
                </div>
             </form>
          </div>
       </div>
       <div *ngIf="page == 4.1" class="form trd">
          <!-- 4.1 -->
          <div class="col-md-6">
             <form [formGroup]="fifthFormGroup">
                <div class="margin">
                   <h1>How will you be paying for your services?</h1>
                   <div class="form-check">
                      <input class="form-check-input" formControlName="haveAuth" value="yes" type="radio"
                         name="haveAuth" id="yes">
                      <label class="form-check-label" for="yes">With Insurance</label>
                   </div>
                   <div class="form-check">
                      <input class="form-check-input" formControlName="haveAuth" value="no" type="radio"
                         name="haveAuth" id="no">
                      <label class="form-check-label" for="no">Without Insurance</label>
                   </div>
                </div>
                <div class="row">
                  <div class="col-md-12 centerbutton">
                     <button class="btn btn-light" type="button" (click)="previous(4.01)">Previous</button>
                     <button class="btn btn-success"
                     *ngIf="fifthFormGroup.value.haveAuth == '' || fifthFormGroup.value.haveAuth == 'yes' "
                     type="button" (click)="next(true, 5)">Next</button>
                     <button class="btn btn-success" *ngIf="fifthFormGroup.value.haveAuth == 'no' " type="submit"
                     (click)="submit(fifthFormGroup.value.haveAuth != '', 7)" >Submit</button>
                     <!--  -->
                  </div>
               </div>
             </form>
             
          </div>
          <div class="col-md-6 rc">
             <div *ngIf="fifthFormGroup.value.haveAuth == 'yes'" class="">
                <div class="card-body insu">
                    
                   <h5 class="card-title">Insurance</h5>
                   <p class="card-text">
                      We accept a wide range of health insurance plans to help you get the care you need. Please answer a few more questions in the next screen(s) to help us understand your insurance.
                   </p>
                   <img src="assets/insurance.png">
                </div>
             </div>
             <div *ngIf="fifthFormGroup.value.haveAuth == 'no'" class="insu2">
                <div class="card-body insu">
                    
                   <h5 class="card-title">Without Insurance</h5>
                   <p class="card-text">
                      We are assuming you are a cash-paying client. We have competitive rates with quality services. Currently, our fee ranges from $59 to $75 per 30 min session. Your therapist will recommend how many sessions will be required.
                   </p>
                   <img src="assets/cash1.png">
                </div>
             </div>
          </div>
         
       </div>
       <div *ngIf="page == 7.1" class="alert alert-success">Let me contact my intake team to schedule your
          Eval.
       </div>
       <div *ngIf="page == 5" class="form">
          <!-- 5 -->
          <form [formGroup]="eightFormGroup">
             <div class="col-md-6 sixth">
                <div>
                   <h1>
                      What kind of insurance plan do you have?
                   </h1>
                   <div>
                      <div class="form-check">
                         <input class="form-check-input" type="radio" name="insurance" id="emp" value="1"
                            formControlName="insurance">
                         <label class="form-check-label" for="emp">Health insurance through your employer</label>
                      </div>
                      <div class="form-check">
                         <input class="form-check-input" type="radio" name="insurance" id="mdcmc" value="2"
                            formControlName="insurance">
                         <label class="form-check-label" for="mdcmc">Medicaid/Medi-Cal</label>
                      </div>
                      <div class="form-check">
                         <input class="form-check-input" type="radio" name="insurance" id="mdc" value="3"
                            formControlName="insurance">
                         <label class="form-check-label" for="mdc">Medicare</label>
                      </div>
                      <div class="form-check">
                         <input class="form-check-input" type="radio" name="insurance" id="ma" value="4"
                            formControlName="insurance">
                         <label class="form-check-label" for="ma">Medicare Advantage</label>
                      </div>
                      <div class="form-check">
                         <input class="form-check-input" type="radio" name="insurance" id="family" value="5"
                            formControlName="insurance">
                         <label class="form-check-label" for="family">Self bought insurance</label>
                      </div>
                   </div>
                   <div class="centerbutton">
                      <button class="btn btn-light" type="button" (click)="previous(4.1)">Previous</button>
                      <button class="btn btn-success" type="submit" (click)="next(validateInsuranceServices(), 7.01)" >Next</button>
                      <!-- -->
                   </div>
                </div>
             </div>
             <div class="col-md-6 ">
               <div class="insu_wat_typ">
                <div class="card-body" *ngIf="!(eightFormGroup.value.insurance == '3'|| eightFormGroup.value.insurance == '4' || eightFormGroup.value.insurance == '1' || eightFormGroup.value.insurance == '2' || eightFormGroup.value.insurance == '5')">
                  
                  <h5 class="card-title">What kind of insurance plan do you have?</h5>
                   <p class="card-text">
                      We accept a wide range of health insurance plans to help you get the care you need. To get
                      you started, we've put together a snapshot of the health insurance accepted at SLP-tele
                   </p>
                   <img src="assets/insu-3.png" class="wat_ins_img"> 
                </div>
                <div class="card-body"  *ngIf="eightFormGroup.value.insurance == '1' ">
                 
                  <h5 class="card-title">Health Insurance from your employer</h5>
                  <p class="card-text">
                      This health insurance is a health policy selected and purchased by your employer and offered to eligible employees and their dependents. 
                      These are also called group plans.  Your employer will typically share the cost of your premium with you.
                   </p>
                   <img src="assets/insu-4.png" class="wat_ins_img"> 
                </div>
                <div class="card-body"  *ngIf="eightFormGroup.value.insurance == '3' ">
                  
                  <h5 class="card-title">Medicare</h5>
                   <p class="card-text">
                      Medicare is a U.S. government health insurance program that subsidizes healthcare services.
                      The plan covers people age 65 or older, younger people who meet specific eligibility
                      criteria, and individuals with certain diseases. Medicare is divided into four categories:
                      Medicare Part A (hospital coverage), Part B (medical coverage.), Part C (also called
                      Medicare Advantage), and Part D for prescription drugs.
                   </p>
                   <img src="assets/Medicare-insu.png" class="wat_ins_img"> 
                </div>
                <div class="card-body" *ngIf="eightFormGroup.value.insurance == '4' ">
                  <h5 class="card-title">Medicare Advantage</h5>
                  <p class="card-text">
                      Medicare Advantage is part of the Medicare program offered to older people and disabled adults who qualify. Also referred to as Medicare Part C, Medicare Advantage (MA) plans are provided by private insurance companies instead of the federal government. They generally include hospital, medical, and prescription drug coverage. Anyone who joins an MA plan still has Medicare.
                   </p>
                   <img src="assets/self-insu.png" class="wat_ins_img">
                </div>
                <div class="card-body" *ngIf="eightFormGroup.value.insurance == '2' ">
                  
                  <h5 class="card-title">Medicaid/Medi-Cal</h5>
                   <p class="card-text">
                      This is a public health insurance program issued by your state government.
                   </p>
                   <img src="assets/insu-2.png" class="wat_ins_img"> 
                </div>
                <div class="card-body" *ngIf="eightFormGroup.value.insurance == '5' ">
                  
                  <h5 class="card-title">Self Bought insurance</h5>
                   <p class="card-text">
                      This is the plan that you have bought directly from the marketplace or as a part of a group.
                   </p>
                   <img src="assets/self-insu-2.png" class="wat_ins_img"> 
                </div>
             </div>
            </div>
          </form>
       </div>
       <div *ngIf="page == 7.01">
          <form [formGroup]="eightFormGroup">
             <div class="col-md-6 add_details ">
                <div>
                   <div *ngIf="eightFormGroup.value.insurance == '1' || eightFormGroup.value.insurance == '5'">
                      <h1>What is your plan type?</h1>
                      <div class="form-check">
                        <input class="form-check-input" type="radio" name="insurancePlan" id="ppo" value="2"
                           formControlName="insurancePlan">
                        <label class="form-check-label" for="ppo">PPO</label>
                     </div>
                      <div class="form-check">
                         <input class="form-check-input" type="radio" name="insurancePlan" id="hmo" value="1"
                            formControlName="insurancePlan">
                         <label class="form-check-label" for="hmo">HMO</label>
                      </div>
                      
                      <div class="form-check">
                         <input class="form-check-input" type="radio" name="insurancePlan" id="epo" value="3"
                            formControlName="insurancePlan">
                         <label class="form-check-label" for="epo">EPO</label>
                      </div>
                      <div class="form-check">
                         <input class="form-check-input" type="radio" name="insurancePlan" id="pos" value="4"
                            formControlName="insurancePlan">
                         <label class="form-check-label" for="pos">POS</label>
                      </div>
                   </div>
                </div>
                <div *ngIf="eightFormGroup.value.insurance == '3' ">
                   <br>
                   <h1>Please enter your Medicare Number</h1>
                   <mat-form-field appearance="outline">
                      <mat-label>Medicare Number</mat-label>
                      <input matInput type="text" formControlName="insuranceId">
                   </mat-form-field>
                   <div>
                      <h1> Do you have a recommendation or referral or prescription from a doctor for speech therapy services?</h1>
                      <div>
                         <div class="form-check">
                            <input class="form-check-input" type="radio" name="prescription" id="pyes"
                               formControlName="prescription" value="yes">
                            <label for="pyes">Yes</label>
                            <div *ngIf="eightFormGroup.value.prescription == 'yes' " class="form-group">
                               <h1 for="resume" class="form-label">Please upload the recommendation if you have
                                  it handy
                               </h1>
                               <div><input (change)="onChange($event)" type="file" id="resume"
                                  class="form-control form-control-sm" formControlName="resume"
                                  name="resume" accept=".pdf, .docx, .doc" ></div>
                            </div>
                         </div>
                         <div class="form-check">
                            <input class="form-check-input" type="radio" name="prescription" id="pno"
                               formControlName="prescription" value="no">
                            <label for="pno">No</label>
                         </div>
                      </div>
                   </div>
                </div>
                <div *ngIf="eightFormGroup.value.insurance == '2' ">
                   <h1>What is the plan type?</h1>
                   <div class="form-check">
                      <input class="form-check-input" type="radio" name="insurancePlan" id="dm" value="1"
                         formControlName="insurancePlan">
                      <label class="form-check-label" for="dm"> Direct Medicaid </label>
                   </div>
                   <div class="form-check">
                      <input class="form-check-input" type="radio" name="insurancePlan" id="mcp" value="2"
                         formControlName="insurancePlan">
                      <label class="form-check-label" for="mcp"> Managed care Medicaid/Medi-Cal </label>
                   </div>
                </div>

                <div *ngIf="eightFormGroup.value.insurance == '4'">
                   <h1>Please provide your insurance name and number</h1>
                   <div>
                      <mat-form-field class="add_details_2" appearance="outline">
                         <input matInput type="text" formControlName="insuranceName" name="insuranceName">
                         <mat-label>Insurance Name</mat-label>
                      </mat-form-field>
                   </div>
                   <div>
                      <mat-form-field class="add_details_2" appearance="outline">
                         <input matInput type="text" formControlName="insuranceId" name="insuranceId">
                         <mat-label>Insurance Number</mat-label>
                      </mat-form-field>
                   </div>
                   <div>
                      <h1> Do you have a recommendation or referral or  prescription from a doctor for speech therapy services?</h1>
                      <div>
                         <div class="form-check">
                            <input class="form-check-input" type="radio" name="prescription" id="pyes"
                               formControlName="prescription" value="yes">
                            <label for="pyes">Yes</label>
                            <div *ngIf="eightFormGroup.value.prescription == 'yes' " class="form-group">
                               <h1 for="resume" class="form-label">Please upload the recommendation if you have
                                  it handy
                               </h1>
                               <div><input (change)="onChange($event)" type="file" id="resume"
                                  class="form-control form-control-sm" formControlName="resume"
                                  name="resume" accept=".pdf, .docx, .doc"></div>
                            </div>
                         </div>
                         <div class="form-check">
                            <input class="form-check-input" type="radio" name="prescription" id="pno"
                               formControlName="prescription" value="no">
                            <label for="pno">No</label>
                         </div>
                      </div>
                   </div>
                </div>
                <div class="centerbutton">
                   <button class="btn btn-light" (click)="previous(5)">Previous </button>
                   <button class="btn btn-success" type="submit" *ngIf="eightFormGroup.value.insurance == '3' || eightFormGroup.value.insurance == '4' " (click)="submit(validateAddDetails())" >Submit</button>
                   <button class="btn btn-success" type="submit" *ngIf="eightFormGroup.value.insurance != '3' && eightFormGroup.value.insurance != '4' " (click)="next(validateAddDetails(), 7.02)"  >Next</button>
                   <!--   -->
                </div>
             </div>
             <div class="col-md-6 insu_wat_typ"
                *ngIf="eightFormGroup.value.insurance == '1' || eightFormGroup.value.insurance == '5'">
                <div class="card-body" *ngIf="eightFormGroup.value.insurancePlan == '1'">
                
                  <h5 class="card-title">HMO</h5>
                   <p class="card-text">
                      If you have this plan, you might need an authorization/referral from PCP or a specialist to get services from a speech therapist.
                   </p>
                   <img src="assets/HMO.png" class="wat_ins_img"> 
                </div>
                <div class="card-body" *ngIf="eightFormGroup.value.insurancePlan == '4'">
                  
                  <h5 class="card-title">POS</h5>
                   <p class="card-text">
                      If you have this plan, you might need an authorization/referral from PCP or a specialist to get services from a speech therapist.
                   </p>
                   <img src="assets/HMO.png" class="wat_ins_img"> 
                </div>
                <div class="card-body" *ngIf="eightFormGroup.value.insurancePlan == '3'">
                
                  <h5 class="card-title">EPO</h5>
                   <p class="card-text">
                      If you have this plan, you can directly schedule a visit with the speech therapist without any authorization. You might need a prescription from the physician in certain cases.
                   </p>
                   <img src="assets/HMO.png" class="wat_ins_img"> 
                </div>
                <div class="card-body" *ngIf="eightFormGroup.value.insurancePlan == '2'">
                 
                  <h5 class="card-title">PPO</h5>
                   <p class="card-text">
                      If you have this plan, you can directly schedule a visit with the speech therapist without any authorization. You might need a prescription from the physician in certain cases. You can also get speech services from us if we are out of network for your plan.
                   </p>
                   <img src="assets/HMO.png" class="wat_ins_img"> 
                </div>
             </div>
             <div class="col-md-6 insu_wat_typ" *ngIf="eightFormGroup.value.insurance == '2' ">
                <div class="card-body" *ngIf="eightFormGroup.value.insurancePlan == '1'">
                 
                    <h5 class="card-title">Direct Medicaid</h5>
                   <p class="card-text">
                      If you have this plan, you might need an authorization/referral from PCP or a specialist to get services from a speech therapist.
                   </p>
                   <img src="assets/self-insu-2.png" class="wat_ins_img">
                </div>
                <div class="card-body" *ngIf="eightFormGroup.value.insurancePlan == '2'">
                  
                  <h5 class="card-title">Managed care Medicaid/Medi-Cal</h5>

                   <p class="card-text">
                      If you have this plan, you will need an authorization from PCP or a specialist to get services from a speech therapist. If you already have authorization handy which is under our name, please upload it in the next screen.
                   </p>
                   <img src="assets/self-insu-2.png" class="wat_ins_img"> 
                </div>
             </div>
             <div class="col-md-6 insu_wat_typ" *ngIf="eightFormGroup.value.insurance == '3' ">
                <div class="card-body">
                 
                  <h5 class="card-title">Medicare</h5>
                   <p class="card-text">
                      Medicare is a U.S. government health insurance program that subsidizes healthcare services. The plan covers seniors and other qualified individuals who meet specific eligibility criteria. Medicare is divided into four categories: Medicare Part A (hospital coverage), Part B (medical coverage.), Part C (also called Medicare Advantage), and Part D for prescription drugs.
                   </p>
                   
                   <img src="assets/Medicare-insu.png" class="wat_ins_img">
                </div>
             </div>
             <div class="col-md-6 insu_wat_typ" *ngIf="eightFormGroup.value.insurance == '4' ">
                <div class="card-body">
                 
                  <h5 class="card-title">Medicare Advantage</h5>
                   <p class="card-text">
                      Medicare Advantage is part of the Medicare program offered to seniors and disabled adults who qualify. Also referred to as Medicare Part C, Medicare Advantage (MA) plans are provided by private insurance companies instead of the federal government. They generally include hospital, medical, and prescription drug coverage. Anyone who joins an MA plan still has Medicare.
                   </p>
                </div>
             </div>
          </form>
       </div>
       <div *ngIf="page == 7.02" class="col-md-6 add_details">
          <form [formGroup]="eightFormGroup">
             <div *ngIf="(eightFormGroup.value.insurancePlan == '2' || eightFormGroup.value.insurancePlan == '3') && (eightFormGroup.value.insurance == '1' || eightFormGroup.value.insurance == '5') ">
                <div>
                   <h1>Please provide your insurance name and number</h1>
                   <div>
                      <mat-form-field class="add_details_2" appearance="outline">
                         <input matInput type="text" formControlName="insuranceName" name="insuranceName">
                         <mat-label>Insurance Name</mat-label>
                      </mat-form-field>
                   </div>
                   <div>
                      <mat-form-field class="add_details_2" appearance="outline">
                         <input matInput type="text" formControlName="insuranceId" name="insuranceId">
                         <mat-label>Insurance Number</mat-label>
                      </mat-form-field>
                   </div>
                   <div>
                      <h1> Do you have a recommendation or referral or  prescription from a doctor for speech therapy services?</h1>
                      <div>
                         <div class="form-check">
                            <input class="form-check-input" type="radio" name="prescription" id="pyes"
                               formControlName="prescription" value="yes">
                            <label for="pyes">Yes</label>
                            <div *ngIf="eightFormGroup.value.prescription == 'yes' " class="form-group">
                               <h1 for="resume" class="form-label">Please upload the recommendation if you have
                                  it handy
                               </h1>
                               <div><input (change)="onChange($event)" type="file" id="resume"
                                  class="form-control form-control-sm" formControlName="resume"
                                  name="resume" accept=".pdf, .docx, .doc"></div>
                            </div>
                         </div>
                         <div class="form-check">
                            <input class="form-check-input" type="radio" name="prescription" id="pno"
                               formControlName="prescription" value="no">
                            <label for="pno">No</label>
                         </div>
                      </div>
                   </div>
                </div>
             </div>
             <div *ngIf="(eightFormGroup.value.insurancePlan == '1' || eightFormGroup.value.insurancePlan == '4') && (eightFormGroup.value.insurance == '1' || eightFormGroup.value.insurance == '5') ">
                <div>
                   <h1>Please provide your insurance name and number</h1>
                   <div>
                      <mat-form-field class="add_details_2" appearance="outline">
                         <input matInput type="text" formControlName="insuranceName" name="insuranceName">
                         <mat-label>Insurance Name</mat-label>
                      </mat-form-field>
                   </div>
                   <div>
                      <mat-form-field class="add_details_2" appearance="outline">
                         <input matInput type="text" formControlName="insuranceId" name="insuranceId">
                         <mat-label>Insurance Number</mat-label>
                      </mat-form-field>
                   </div>
                   <h1>Do you have an authorization?</h1>
                   <div>
                      <div class="form-check">
                         <input class="form-check-input" type="radio" name="auth" id="ayes"
                            formControlName="auth" value="yes">
                         <label class="form-check-label" for="ayes">Yes</label>
                         <div *ngIf="eightFormGroup.value.auth == 'yes' " class="form-group">
                            <h1 for="resume" class="form-label">Please upload the authorization if you have it
                               handy
                            </h1>
                            <div><input (change)="onChange($event)" type="file" id="resume"
                               class="form-control form-control-sm" formControlName="resume" accept=".pdf, .docx, .doc" name="resume">
                            </div>
                         </div>
                      </div>
                      <div class="form-check">
                         <input class="form-check-input" type="radio" name="auth" id="ano" formControlName="auth"
                            value="no">
                         <label class="form-check-label" for="ano">No</label>
                      </div>
                   </div>
                </div>
             </div>
             <div *ngIf="eightFormGroup.value.insurancePlan== '1' && eightFormGroup.value.insurance == '2'   ">
                <h1>Please provide your Medicaid/Medi-Cal Number</h1>
                <mat-form-field class="add_details_2" appearance="outline">
                   <mat-label>Medicaid/Medi-Cal Number</mat-label>
                   <input matInput type="text" formControlName="insuranceId">
                </mat-form-field>
                <div>
                   <h1>Do you have a recommendation or referral or  prescription from a doctor for speech therapy services?</h1>
                   <div>
                      <div class="form-check">
                         <input class="form-check-input" type="radio" name="auth" id="ayes"
                            formControlName="auth" value="yes">
                         <label class="form-check-label" for="ayes">Yes</label>
                         <div *ngIf="eightFormGroup.value.auth == 'yes' " class="form-group">
                            <h1 for="resume" class="form-label">Please upload the recommendation if you have it
                               handy
                            </h1>
                            <div><input (change)="onChange($event)" type="file" id="resume"
                               class="form-control form-control-sm" formControlName="resume" accept=".pdf, .docx, .doc" name="resume">
                            </div>
                         </div>
                      </div>
                      <div class="form-check">
                         <input class="form-check-input" type="radio" name="auth" id="ano" formControlName="auth"
                            value="no">
                         <label class="form-check-label" for="ano">No</label>
                      </div>
                   </div>
                </div>
             </div>
             <div>
                <div *ngIf="eightFormGroup.value.insurancePlan == '2' && eightFormGroup.value.insurance == '2'">
                   <h1>Please provide your managed care plan name and number</h1>
                   <mat-form-field class="add_details_2" appearance="outline">
                      <input matInput type="text" formControlName="insuranceName" name="insuranceName">
                      <mat-label>Managed Care Plan Name</mat-label>
                   </mat-form-field>
                <!-- </div>
                <div *ngIf="eightFormGroup.value.insurancePlan== '2' && eightFormGroup.value.insurance == '2'"> -->
                   <mat-form-field class="add_details_2" appearance="outline">
                      <input matInput type="text" formControlName="insuranceId" name="insuranceId">
                      <mat-label>Managed Care Plan Number</mat-label>
                   </mat-form-field>
                   <h1>Do you have an authorization?</h1>
                   <div>
                      <div class="form-check">
                         <input class="form-check-input" type="radio" name="auth" id="ayes"
                            formControlName="auth" value="yes">
                         <label class="form-check-label" for="ayes">Yes</label>
                         <div *ngIf="eightFormGroup.value.auth == 'yes' " class="form-group">
                            <h1 for="resume" class="form-label">Please upload the authorization if you have it
                               handy
                            </h1>
                            <div><input (change)="onChange($event)" type="file" id="resume"
                               class="form-control form-control-sm" formControlName="resume"  accept=".pdf, .docx, .doc" name="resume">
                            </div>
                         </div>
                      </div>
                      <div class="form-check">
                         <input class="form-check-input" type="radio" name="auth" id="ano" formControlName="auth"
                            value="no">
                         <label class="form-check-label" for="ano">No</label>
                      </div>
                   </div>
                </div>
             </div>
             <div class="centerbutton">
                <button class="btn btn-light" (click)="previous(7.01)">Previous </button>
                <button class="btn btn-success" type="submit" (click)="submit(validateSevenPointZeroTwo())" >Submit</button>
             </div>
          </form>
       </div>
       <div *ngIf="page == 7">
          <div class="alert" style="text-align:center;">
            <span class="last_msg" >
            <a href="https://calendly.com/crystaldawes">Click here</a> to schedule an appointment with our consultation specialist. 
            <br>You can also text or call them on the direct line 818-732-8244.
            </span>
            <br> <br>
            <span class="last_msg_2" >
               Let us find the provider who best fits your needs. 
               <!-- You can also find the provider who is right for you by using the search below. -->
            </span>
          </div>
       </div>
       <!-- <div class="row therip_search_mn search_div " *ngIf="page == 7">
          <app-search-therapist show="false" [lang]="thirdFormGroup.value.languages" [state]="getStateWithZip(thirdFormGroup.value.zipcode)" style="margin: 0px !important;"></app-search-therapist>
       </div> -->
       <div *ngIf="page == 8">
          <div class="alert alert-success">We found a current authorization for Speech Therapy serrvices from your
             health plan in our databas. Let me contact my intake team to schedule your
             Eval.
          </div>
       </div>
    </div>
 </div>